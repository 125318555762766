import React, { Component, useEffect, useState } from 'react';

import './auditChartFilters.scss';
import './audits.scss';

type CityName = 'Pass' | 'Fail';

import axios from 'axios';
import { chainPropTypes } from '@mui/utils';

// Imports from Charts.js
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export const options = {
  indexAxis: 'x' as const,
  normalized: true,
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  
  // makes the chart stretch to full container
  // this was originally True
  responsive: true,
  plugins: {
    legend: {
      // puts a legend at the top of the chart
      display: false,
    },
    datalabels: {
      display(context) {
        return context.dataset.data[context.dataIndex] !== 0;
      },
      // change these to center to put on-bar labels in middle of bar, use end on both to put it outside of bar
      anchor: 'end' as const,
      align: 'start' as const,
      labels: {
        value: {},
        title: {
          color: 'white',
          fontWeight: 'bold',
        },
      },
    },
  },
  scales: {
    x: {
      parsing: false,
      stacked: true,
      // changes the gridlines
      ticks: {
        stepSize: 1,
      },
    },
    y: {
      parsing: false,
      stacked: true,
    },
  },
};

const ChainBarChart = props => {
    const [chainData, setChainData] = useState(null);
  const [filterType, setFilterType] = useState('top10');

  useEffect(() => {
    getData();
  }, [props.auditID]);

  const getData = async () => {
    const chainURL = 'api/user/audit/' + props.auditID + '/drilldown?groupBy=chainName';
    const chainFetch = await (await axios.get(chainURL)).data;
    setChainData(chainFetch._embedded?.auditSummaryDTOList);
  };

  let data = null;
  let labels = null;
  let failData = null;
  let passData = null;
  let totalData = null;
  let rateHigher = null;
  let finalData = null;
  let barColor = null;
  let hoverLabel = null;

  if (chainData) {
    const sortedChainData = [...chainData].sort((a, b) => b.totalProcessed - a.totalProcessed);
    const revSortedChainData = [...chainData].sort((a, b) => a.totalProcessed - b.totalProcessed);


    if (filterType === 'top10') {
      labels = sortedChainData.map(function (e) {
        return e.groupByValue;
      });  
    } else if (filterType === 'bottom10') {
      labels = revSortedChainData.map(function (e) {
        return e.groupByValue;
      });
    } else {
      labels = sortedChainData.map(function (e) {
        return e.groupByValue;
      }); 
    }

    
    labels.length = Math.min(labels.length, 5);
    
    if (filterType === 'top10') {
      data = sortedChainData.map(function (e) {
        const ele = {
          name: e.groupByValue,
          Pass: e.success,
          Fail: e.totalProcessed - e.success,
          total: e.totalProcessed,
        };
        return ele;
      });
    
      failData = sortedChainData.map(function (e) {
        return e.totalProcessed - e.success;
      });

      passData = sortedChainData.map(function (e) {
        return e.success;
      });

      totalData = sortedChainData.map(function (e) {
        return e.totalProcessed;
      });
    
      rateHigher = sortedChainData.map(function (e) {
        return e.rateLoadedHigher;
      });

    } else {
      data = revSortedChainData.map(function (e) {
        const ele = {
          name: e.groupByValue,
          Pass: e.success,
          Fail: e.totalProcessed - e.success,
          total: e.totalProcessed,
        };
        return ele;
      });
      
      failData = revSortedChainData.map(function (e) {
        return e.totalProcessed - e.success;
      });
  
      passData = revSortedChainData.map(function (e) {
        return e.success;
      });
  
      totalData = revSortedChainData.map(function (e) {
        return e.totalProcessed;
      });
      
      rateHigher = revSortedChainData.map(function (e) {
        return e.rateLoadedHigher;
      });
    }

    // UPDATE THESE METRICS AND THE ABOVE METRIC NAMES ACCORDINGLY
    if (props.selectedMetric === 'loaded') {
      finalData = failData;
      barColor =  'rgb(0, 32, 103)';
      hoverLabel = "Rates Loaded";
    } else if (props.selectedMetric === 'availability') {
      finalData = failData;
      barColor =  'rgb(86, 200, 155)';
      hoverLabel = "Rate Availability";
    } else if (props.selectedMetric === 'accuracy') {
      finalData = failData;
      barColor =  'rgb(189, 24, 101)';
      hoverLabel = "Rate Accuracy";
    } else if (props.selectedMetric === 'higher') {
      finalData = failData;
      barColor =  'rgb(255, 193, 7)';
      hoverLabel = "Rates Loaded Higher";
    } else {
      finalData = failData; 
      barColor =  'rgb(0, 32, 103)';
      hoverLabel = "Rates Loaded";
    }



    // This should match the props
    }

  const graphData = {
    labels,
    datasets: [
      {
        axis: 'x',
        label: hoverLabel,
        data: finalData,
        backgroundColor: barColor,
      },
    ],
  };

  const handleFilterClick = (type) => {
    setFilterType(type);
  };

  return (
    <div>
      <div className="chartFilterContainer">
        <button className="chartFilter-button" onClick={() => handleFilterClick('top10')}>Top 5</button>
        <button className="chartFilter-button" onClick={() => handleFilterClick('bottom10')}>Bottom 5</button>
      </div>
      <Bar width={450} height={275} data={graphData} options={options} />
      <h1>

      </h1>
      {/* <Tooltip />
          <CartesianGrid stroke="#C4C4C4" horizontal={false} />
          <Bar dataKey="Pass" stackId="a" fill="#55C88E" barSize={25} />
          <Bar dataKey="Fail" stackId="a" fill="#ED3743" />
          <XAxis
            type="number"
            dataKey="total"
            tickCount={4}
            tickLine={false}
            label={{ value: 'TOTAL NUMBER OF HOTELS', position: 'bottom', offset: 20 }}
          />
          <YAxis type="category" dataKey="name" tickLine={false} /> */}
    </div>
  );
};

export default ChainBarChart;