import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Hotel',
    hotels: 26,
  },
  {
    name: 'Air',
    air: 42,
  },
  {
    name: 'Cars',
    cars: 100,
  },
];

const HorizontalBarChart = () => (
  <ResponsiveContainer width="90%" height="30%">
    <BarChart width={110} height={40} data={data} layout="vertical">
      <Legend verticalAlign="top" iconType="circle" />
      <Bar dataKey="cars" stackId="a" fill="#FF8042" />
      <Bar dataKey="air" stackId="a" fill="#00C49F" />
      <Bar dataKey="hotels" stackId="a" fill="#0088FE" />
      <XAxis type="number" dataKey="cars" />
      <YAxis hide={true} type="category" dataKey="name" />
    </BarChart>
  </ResponsiveContainer>
);

export default HorizontalBarChart;
