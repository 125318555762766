import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Berlin',
    thisYear: 2.7,
    previousYear: 2.1,
    amt: 2400,
  },
  {
    name: 'LA',
    thisYear: 1.9,
    previousYear: 1.7,
    amt: 2210,
  },
  {
    name: 'San Diego',
    thisYear: 2.6,
    previousYear: 2.2,
    amt: 2290,
  },
  {
    name: 'San Jose',
    thisYear: 2.5,
    previousYear: 2.1,
    amt: 2000,
  },
  {
    name: 'Philadelphia',
    thisYear: 2.9,
    previousYear: 2.1,
    amt: 2181,
  },
  {
    name: 'Taipei',
    thisYear: 1.9,
    previousYear: 1.6,
    amt: 1.7,
  },
  {
    name: 'Mexico City',
    thisYear: 2.7,
    previousYear: 2.2,
    amt: 2.1,
  },
  {
    name: 'Dallas',
    thisYear: 2.2,
    previousYear: 1.7,
    amt: 2100,
  },
  {
    name: 'Las Vegas',
    thisYear: 2.9,
    previousYear: 2.3,
    amt: 2100,
  },
  {
    name: 'London',
    thisYear: 1.8,
    previousYear: 1.4,
    amt: 2100,
  },
];

const RenderLineChart = () => (
  <BarChart width={925} height={325} data={data} margin={{ top: 20, right: 40, bottom: 5, left: 0 }}>
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Bar dataKey="previousYear" stackId="a" fill="#2699D3" />
    <Bar dataKey="thisYear" stackId="a" fill="#27447C" />
  </BarChart>
);

export default RenderLineChart;
