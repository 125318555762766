import React, { useState } from 'react';

import CustomizeAudit from './advancedAuditCustomizeAudit';

import './advancedAuditModal.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface AdvancedAuditModal {
  active;
  toggleAdvancedAuditModalStatus;
}

const AdvancedAuditModal = props => {
  const [currentPage, setCurrentPage] = useState(0);
  const [enableAlternateAudits, setEnableAlternateAudits] = useState(false);

  const pageStepper = page => {
    switch (page) {
      case 0:
        return <CustomizeAudit />;
      default:
        return <CustomizeAudit />;
    }
  };

  return (
    <div className={props.active ? 'activeModal' : 'modal'}>
      <div className="modalContainer">
        <div className="standardAuditCancelContainer">
          <span className="closeModal" onClick={() => props.toggleAdvancedAuditModalStatus()}>
            &times;
          </span>
        </div>
        <div className="modalContentContainer">{pageStepper(currentPage)}</div>
      </div>
    </div>
  );
};

export default AdvancedAuditModal;
