import React, { useState, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import UserContext from 'app/userContext';

import './header.scss';
const Header = () => {
  const { currentUser } = useContext(UserContext);

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      alert('error signing out: ');
    }
  };

  return (
    <div className="Header">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <Link className="homeLink" to="/audits">
        <img className="FerskLogo" src="../../../../content/images/FerskTech.png" alt="" />
      </Link>

      {/* <div className="SearchBox">
      <button>
        <i className="fa fa-search"></i>
      </button>
      <input type="text" placeholder="Search..."></input>
    </div> */}

      {/* <a className="comingSoonLink" href="https://fersktech.com/" rel="noreferrer" target="_blank">
      <p className="comingSoon">Coming Soon</p>
    </a> */}

      <button className="ProfilePic">
        <img src="../../../../content/images/FersktechLogo.png" alt="" />
      </button>
      <h1 className="ProfileName">{currentUser}</h1>

      <Link className="logoutLink" to="/" onClick={() => signOut()}>
        <p>Log Out</p>
      </Link>
    </div>
  );
};

export default Header;
