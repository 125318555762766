import React, { useState } from 'react';

import './advancedAuditCustomizeAudit.scss';

const CustomizeAudit = props => {
  const [reverseAudit, setReverseAudit] = useState(false);
  const [commissionAudit, setCommissionAudit] = useState(false);

  const toggleReverseAudit = () => {
    setReverseAudit(!reverseAudit);
    setCommissionAudit(false);
  };

  const toggleCommissionAudit = () => {
    setCommissionAudit(!commissionAudit);
    setReverseAudit(false);
  };

  return (
    <div className="customizeAdvancedAudit">
      <h1 className="advancedAuditTitle">ADVANCED AUDITS</h1>
      <p className="customizeAdvancedAuditTitle">
        Advanced Audits are not available yet, but they will allow you to audit your program against more advanced criteria!
      </p>
      <div className="advancedAuditSelectionContainer">
        <div className="upperAuditsContainer">
          <div
            className={reverseAudit ? 'commissionAuditContainerActive' : 'commissionAuditContainer'}
            onClick={() => toggleReverseAudit()}
          >
            {/* {reverseAudit ? (*/}
            {/*  <div className="selectionCircleReverseAudit">*/}
            {/*    <div className="activeIndicatorCircleReverseAudit"></div>*/}
            {/*  </div>*/}
            {/* ) : (*/}
            {/*  <div className="selectionCircleReverseAudit"></div>*/}
            {/* )}*/}

            <h2 className="commissionAuditTitle">Commission Audit</h2>
            <p>
              Ensure your commissionable
              <br />
              rates are loaded with the
              <br />
              correct comission percentage
            </p>
          </div>

          <div
            className={commissionAudit ? 'reverseAuditContainerActive' : 'reverseAuditContainer'}
            onClick={() => toggleCommissionAudit()}
          >
            {commissionAudit ? (
              <div className="selectionCircleCommissionAudit">
                <div className="activeIndicatorCircleCommissionAudit"></div>
              </div>
            ) : (
              <div className="selectionCircleCommissionAudit"></div>
            )}

            <h2 className="reverseAuditTitle">Reverse/Squatter Audit</h2>
            <p className="reverseAuditDescription">
              Identifies non-preferred hotels
              <br />
              falsely loading client negotiated
              <br />
              rates codes
            </p>
          </div>
        </div>

        <div className="lowerAuditsContainer">
          {/*  <div className="rateParityAuditContainer">*/}
          {/*    <div className="comingSoonIndicator">*/}
          {/*      <h3>coming soon</h3>*/}
          {/*    </div>*/}

          {/*    <h2 className="rateParityAuditTitle">Rate Parity Audit</h2>*/}
          {/*    <p className="rateParityAuditDescription">*/}
          {/*      compare your negotiated rates*/}
          {/*      <br />*/}
          {/*      across third-party sites*/}
          {/*    </p>*/}
          {/*  </div>*/}

          {/*  <div className="competitiveBARAuditContainer">*/}
          {/*    <div className="comingSoonIndicator">*/}
          {/*      <h3>coming soon</h3>*/}
          {/*    </div>*/}

          {/*    <h2 className="competitiveBARAuditTitle">Competitive BAR Audit</h2>*/}
          {/*    <p className="competitiveBARAuditDescription">*/}
          {/*      Track and report BAR rates for*/}
          {/*      <br />*/}
          {/*      negotiated properties throughout*/}
          {/*      <br />*/}
          {/*      year for forecasting and stronger*/}
          {/*      <br />*/}
          {/*      rate negotiations*/}
          {/*    </p>*/}
          {/*  </div>*/}
        </div>
      </div>
    </div>
  );
};

export default CustomizeAudit;
