import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSyncAlt, faCalendarAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@material-ui/core';

interface AuditRow {
  data;
  active;
  toggleModal;
  toggleViewAndDownloadModal;
  toggleRerunAuditModal;
  refreshAudits;
  passAuditID;
  passDeleteAuditID;
  passAuditName;
  passScheduleType;
  setDownloadStatus;
  updateID;
  passLastRunDate;
  toggleDeleteAuditModalStatus;
}

// add useffect hook to get audit schedule type

const AuditRow = props => {
  const [percentComplete, setPercentComplete] = useState(100);
  const [auditRerun, setAuditRerun] = useState(false);

  useEffect(() => {
    if (props.data.auditScheduleTypeName.name === 'ADHOC_AUDIT') {
      if (!props.data.lastRunDate) {
        getPercentComplete();
      }
    }
  }, [props.data.lastRunDate]);

  const getPercentComplete = async () => {
    const summaryData = await axios.get(props.data._links.summary.href.toString());
    const percent = (parseInt(summaryData.data.percentProcessed, 10) * 100).toFixed(0);
    const percentInt = parseInt(percent, 10);
    setPercentComplete(percentInt);
    if (percentInt !== 100) {
      setTimeout(() => {
        getPercentComplete();
      }, 2000);
    }
  };

  function reRunAudit(id, refreshAudits) {
    const myURL = '/api/user/audit/' + id + '/run';
    axios.post(myURL).then(function (response) {
      if (response.statusText === 'OK') {
        refreshAudits();
      }
    });
  }

  function formatDate(date) {
    const dateData = date.split('-');
    let month = null;

    switch (dateData[1]) {
      default:
        month = 'null';
        break;
      case '01':
        month = 'Jan';
        break;
      case '02':
        month = 'Feb';
        break;
      case '03':
        month = 'Mar';
        break;
      case '04':
        month = 'Apr';
        break;
      case '05':
        month = 'May';
        break;
      case '06':
        month = 'Jun';
        break;
      case '07':
        month = 'Jul';
        break;
      case '08':
        month = 'Aug';
        break;
      case '09':
        month = 'Sep';
        break;
      case '10':
        month = 'Oct';
        break;
      case '11':
        month = 'Nov';
        break;
      case '12':
        month = 'Dec';
        break;
    }

    return `${month} ${dateData[2]}, ${dateData[0]}`;
  }

  const enableDeleteAuditModal = () => {
    props.passDeleteAuditID(props.data.auditID);
    props.passAuditName(props.data.auditID);
    props.toggleDeleteAuditModalStatus();
  };

  const getNextRunDate = () => {
    if (props.data.auditScheduleTypeName) {
      if (props.data.auditScheduleTypeName.name === 'RECURRING_AUDIT') {
        if (props.data.nextRunDate) {
          return (formatDate(props.data.nextRunDate));
        } else {
          return ('Inactive');
        }
      } else {
        return ('On Demand');
      }
    } else {
      return ('missing');
    }
  }

  return (
    <div className="auditRow">
      <div className="auditRowContent auditTypeRR">
        <div className={props.active ? 'newIndicatorActive' : 'newIndicatorInactive'}>
          <p>new</p>
        </div>
        <p>Standard Audit</p>
      </div>
      <div className=" auditRowContent auditNameRR">
        <p className="auditTypeText">{props.data.auditName}</p>
      </div>

      <div className="auditRowContent frequencyRR">
        <p>{props.data.frequency ? props.data.frequency.description : 'One-Time'}</p>
      </div>
      <div className="auditRowContent lastRunDateRR">
        <p>{props.data.lastRunDate ? formatDate(props.data.lastRunDate) : 'Pending'}</p>
      </div>
      <div className="auditRowContent nextRunDateRR">
        <p>{getNextRunDate()}</p>
      </div>
      <div className="auditRowContent auditRunStatusRR">
        {
          props.data.auditScheduleTypeName.name === 'RECURRING_AUDIT' ?
            <div className="scheduledIndicator">SCHEDULED</div>
            :
            props.data.lastRunDate ?
              <div className="completedIndicator">COMPLETED</div>
              :
              <p>Processing...</p>
        }
      </div>
      <div className="auditRowContent recentAuditButtons actionsRR">
        <Tooltip title="View Details" placement="top">
          <div className="viewAndDownloadAuditButton">
            <FontAwesomeIcon
              className="viewAndDownload"
              icon={faEye}
            // onClick={() => {
            //   props.passAuditID(props.data.auditID);
            //   props.passScheduleType(props.data.auditScheduleTypeName.description);
            //   props.passLastRunDate(props.data.lastRunDate);
            //   props.setDownloadStatus(true, false, false, false);
            //   props.toggleViewAndDownloadModal();
            // }}
            />
          </div>
        </Tooltip>

        {/* <Tooltip title={auditRerun ? 'Audit has been run today. You can run another for this property tomorrow.' : 'Rerun'} placement="top"> */}
        <Tooltip title={'Rerun: Coming Soon'} placement="top">
          <div className='fa-disabled'>
            {auditRerun ? (
              <FontAwesomeIcon
                className="rerunDisabled"
                icon={faSyncAlt}
              // onClick={
              //   () => {
              //     props.toggleRerunAuditModal();
              //     setAuditRerun(false);
              //   }
              //   // reRunAudit(props.data.auditID, props.refreshAudits)
              // }
              />
            ) : (
              <FontAwesomeIcon
                className="rerun"
                icon={faSyncAlt}
              // onClick={() => {
              //   // reRunAudit(props.data.auditID, props.refreshAudits);
              //   props.toggleRerunAuditModal(), setAuditRerun(true);
              // }}
              />
            )}
          </div>
        </Tooltip>

        <Tooltip title="Cancel Audit" placement="top">
          <div>
            <FontAwesomeIcon className="delete" icon={faTrashAlt} onClick={() => enableDeleteAuditModal()} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default AuditRow;
