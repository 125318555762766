import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';

// import DevTools from './config/devtools';
import initStore from './config/store';
import setupAxiosInterceptors from './config/axios-interceptor';
import { clearAuthentication } from './shared/reducers/authentication';
import ErrorBoundary from './shared/error/error-boundary';
import AppComponent from './app';
import { loadIcons } from './config/icon-loader';

import Amplify from 'aws-amplify';

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id: '',
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
  oauth: {},
});

// const devTools = process.env.NODE_ENV === 'development' ? <DevTools /> : null;

const store = initStore();

const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

loadIcons();

const root = ReactDOM.createRoot(document.getElementById('root'));

const render = Component =>
  // eslint-disable-next-line react/no-render-return-value
  root.render(
    <ErrorBoundary>
      <Provider store={store}>
        {/* If this slows down the app in dev disable it and enable when required  */}
        {/* {devTools} */}
        <Component />
      </Provider>
    </ErrorBoundary>
  );

render(AppComponent);
