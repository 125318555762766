import React, { useState, useEffect } from 'react';

import './upload.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus, faFolder } from '@fortawesome/free-solid-svg-icons';

interface Upload {
  uploadCallbackFunction;
  nameChangeCallbackFunction;
  passAuditFile;
  toggleModal;
  previousPage;
  blueButtonText;
  blueButtonFunction;
}

const Upload = props => {
  const [uploadFile, setUploadFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [uploadErrorStatus, setUploadErrorStatus] = useState(false);
  const [nameErrorStatus, setNameErrorStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');
  const [inputInit, setInputInit] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('Please upload a file to audit');

  const prepareUploadFile = event => {
    if (event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setUploadFile(event.target.files[0]);
      props.uploadCallbackFunction(event.target.files[0]);
      setFileName(event.target.files[0].name);
      setUploadErrorStatus(false);
    } else {
      setUploadErrorMessage('You uploaded an invalid file type. Please upload a .xlsx file!');
      setUploadErrorStatus(true);
    }
  };

  const triggerAudit = () => {
    if (uploadFile != null) {
      if (name.length === 0) {
        setNameErrorStatus(true);
        setErrorMessage('Please fill out an audit name');
      } else if (name.length > 20) {
        setNameErrorStatus(true);
        setErrorMessage('Your audit name must be less than 20 characters');
      } else if (name === '') {
        setNameErrorStatus(true);
        setErrorMessage('Please fill out an audit name');
      } else if (name.endsWith(' ')) {
        setNameErrorStatus(true);
        setErrorMessage('Your audit name cannot end with a space');
      } else {
        props.blueButtonFunction();
      }
    } else {
      setUploadErrorStatus(true);
    }
  };

  const handleNameChange = event => {
    setName(event.target.value);
    props.nameChangeCallbackFunction(event.target.value);
  };

  return (
    <div className="upload">
      <div className="modalHeader">
        <h1 className="modalTitle">UPLOAD FILE</h1>
      </div>
      <div className="modalContent">
        <input className="textField" id="textField" type="text" placeholder="Name your audit" onChange={handleNameChange} />
        <div className="inputGroup">
          <div className={uploadErrorStatus ? 'auditUploadErrorContainer' : 'auditUploadContainer'}>
            <input className="auditUpload" type="file" id="audit-upload-input" onChange={e => prepareUploadFile(e)} />
            {uploadErrorStatus ? (
              <div className="errorContainer">
                <FontAwesomeIcon className="faFolderError" icon={faFolder} />
                <p className="testThis">{uploadErrorMessage}</p>
              </div>
            ) : uploadFile ? (
              <div className="successContainer">
                <FontAwesomeIcon className="faFolder" icon={faFolder} />
                <label className="testThis" htmlFor="audit-upload-input">You have successfully added {fileName}!</label>
              </div>
            ) : (
              <div className="testContainer">
                <FontAwesomeIcon className="faFolderPlus" icon={faFolderPlus} />
                <label className="testThis" htmlFor="audit-upload-input">Drag and drop your files here</label>
                  </div>
            )}
            
          </div>
          <p className="inputHelper">.CSV, .XLS, .XLSX files only</p>
          {nameErrorStatus ? (
            <div className="changeNameError">
              <p>{errorMessage}</p>
            </div>
          ) : (
            <div className="changeNameErrorInactive"></div>
            )}
        </div>
      </div>

      <div className="uploadButtonContainer">
        <button className="button button-outline" onClick={() => props.previousPage()}>
          <p className="button-text">
            Cancel
          </p>
        </button>
        <button className="button button-filled" onClick={() => triggerAudit()}>
          <p className="button-text">
            {props.blueButtonText}
          </p>
        </button>
        
      </div>
    </div>
  );
};

export default Upload;
