import React from 'react';

import './programoverview.scss';
import RenderLineChart from './barChart';
import PieChart from './pieChart';
import HorizontalBarChart from './horizontalBarChart';

const ProgramOverview = () => (
  <div className="ProgramOverview">
    <h1>PROGRAM OVERVIEW</h1>
    <div className="ProgramProgress container">
      <h2 className="progressTitle title">PROGRAM PROGRESS</h2>
      {/* <div className="progressBar"></div> */}
    </div>
    <div className="dropdownButtons">
      <button className="dropdownButton audits">
        <h3>MANAGE PROGRAMS</h3>
      </button>
      <button className="dropdownButton rfpContracts">
        <h3>RFP/ CONTRACTS</h3>
      </button>
      <button className="dropdownButton managePrograms">
        <h3>AUDITS</h3>
      </button>
      <button className="dropdownButton programAnalytics">
        <h3>PROGRAM ANALYTICS</h3>
      </button>
    </div>
    <div className="lowerContainer">
      <div className="overallHealth lowerBox">
        <h2 className="healthTitle title">OVERALL HEALTH OF PROGRAM</h2>
        <RenderLineChart />
      </div>
      <div className="summaryBox lowerBox">
        <h2 className="summaryTitle title">PROGRAM OVERVIEW SUMMARY</h2>
        <PieChart />
        <div className="titleContainer">
          <h4>Overall Progress</h4>
        </div>

        <HorizontalBarChart />
        <button className="programAnalyticsButton">PROGRAM ANALYTICS</button>
      </div>
    </div>
  </div>
);

export default ProgramOverview;
