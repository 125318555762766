import React, { useState, useEffect } from 'react';
import { Authenticator, SignIn, RequireNewPassword, ForgotPassword, Greetings, AmplifyTheme } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import './login.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Input, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [logingIn, setLogingIn] = useState(false);
  const [requireNewPassword, setRequireNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  const [codeSentStatus, setCodeSentStatus] = useState(false);
  const [code, setCode] = useState('');
  const [user, setUser] = useState(null);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleUser = event => {
    if (event.target.value.includes(' ')) {
      setUserName(event.target.value.split(' ').join(''));
    } else {
      setUserName(event.target.value);
      setErrorStatus(false);
    }
  };

  const handlePassword = event => {
    setPassword(event.target.value);
    setErrorStatus(false);
  };

  const handleNewPassword = event => {
    setNewPassword(event.target.value);
    setErrorStatus(false);
  };

  const handleCode = event => {
    setCode(event.target.value);
    setErrorStatus(false);
  };

  const [matches, setMatches] = useState(window.matchMedia('(min-width: 1075px)').matches);

  useEffect(() => {
    window.matchMedia('(min-width: 1075px)').addEventListener('change', e => setMatches(e.matches));
  }, []);

  async function signIn() {
    try {
      setLogingIn(true);
      const userObject = await Auth.signIn(userName, password);
      setPassword('');

      if (userObject.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(userObject);
        setRequireNewPassword(true);
      } else if (userObject) {
        window.location.assign('/audits');
      }
    } catch (error) {
      setLogingIn(false);
      setErrorStatus(true);
      if (error.message === 'Custom auth lambda trigger is not configured for the user pool.') {
        setErrorMessage('Please enter your password');
      } else if (error.message === 'User does not exist.') {
        setErrorMessage('Incorrect username or password.');
      } else {
        setErrorMessage(error.message);
      }
    }
  }

  async function changePassword() {
    try {
      await Auth.completeNewPassword(user, newPassword);
      setRequireNewPassword(false);
      setLogingIn(false);
      setNewPassword('');
    } catch (error) {
      setErrorStatus(true);
      setErrorMessage(error.message);
    }
  }

  async function forgotPassword() {
    try {
      await Auth.forgotPassword(userName);
      setCodeSentStatus(true);
    } catch (error) {
      setErrorStatus(true);
      setErrorMessage(error.message);
    }
  }

  async function forgotPasswordSubmit() {
    try {
      await Auth.forgotPasswordSubmit(userName, code, newPassword);
      setForgotPasswordStatus(false);
      setCodeSentStatus(false);
      setUserName('');
      setPassword('');
      setNewPassword('');
      setCode('');
    } catch (error) {
      setErrorStatus(true);
      if (error.message === "'password' failed to satisfy constraint: Member must satisfy regular expression") {
        setErrorMessage('Your password did not meet the requirements. Please try again');
      }
      setErrorMessage(error.message);
    }
  }

  return (
    <div className="loginPage">
      <div className="loginPageContainer">
        <div className="imageContainer">
          <div className="firstCircle">
            <img src="../../../../content/images/FersktechLogo.png" alt="" />
          </div>
          <div className="secondCircle">
            <img src="../../../../content/images/PeopleStanding.svg" alt="" />
          </div>
          <div className="thirdCircle">
            <blockquote className="textThirdImage">
              <p className="firstText">
                <strong>
                  Finally, a Comprehensive Travel <br />
                  Program Management Solution
                </strong>
              </p>

              <div className="secondText">
                FerskTech’s powerful new travel program management platform integrates <br />
                air, hotel, and car – in one convenient platform – optimizing travel manager <br />
                efficiency, oversight, and savings.
              </div>
              <a href="https://fersktech.hubspotpagebuilder.com/ferskauditsupport" rel="noreferrer" target="_blank">
                <button className="helpButton"> Help &amp; Support</button>
              </a>
            </blockquote>
          </div>

          {/* KEEP */}
          {/* <div className="pageTextContainer">
            <h2 className="toolDescription">All-In-One Travel Sourcing Solution.</h2>
            <p className="toolParagraph">
              FerskTech&apos;s powerful new Corporate Travel Sourcing Solution
              <br />
              integrates Air, Hotel, and Car in one convenient platform, optimizing
              <br />
              travel manager efficiency, oversight, and savings.
            </p>
          </div>
          <div className="helpAndSupportContainer">
            <div className="mailIconContainer">
              <img src="../../../content/images/mail.png" alt="" />
            </div>
            <div className="helpAndSupportTextContainer">
              <h3>HELP AND SUPPORT</h3>
              <p>
                <a href="https://fersktech.com/">
                  <u>support@fersktech.com</u>
                </a>
              </p>
            </div>
          </div>
          <div className="prototypeDisclaimerContainer">
            <div className="prototypeDisclaimer">
              <p>Limited Functionality Prototype</p>
            </div>
          </div> */}
        </div>

        <div className="loginContainer">
          <div className="logoSection">
            <img className="ferskTechLogo" src="../../../content/images/FerskTech.png" alt="" />
            <h3 className="loginDescription">Login to your account</h3>
          </div>

          {/* KEEP FOR DEBUGGIN */}
          {/* <Authenticator hideDefault={true}>
            <SignIn />
            <Greetings />
            <RequireNewPassword />
            <ForgotPassword />
          </Authenticator> */}

          {/* <div className="loginFields"> */}
          {requireNewPassword ? (
            <div className="newPasswordRequired">
              <h2 className="newPasswordDescription">
                {' '}
                Since this is your first time signing in, we will need you to create a new password.{' '}
              </h2>
              <div className="passwordContainer">
                <p className="password">New Password</p>
                <input
                  className="passwordInput"
                  type="password"
                  placeholder="************"
                  value={newPassword}
                  onChange={handleNewPassword}
                />
              </div>

              {errorStatus ? <div className="errorBox">{errorMessage}</div> : <div className="errorPlaceholder"></div>}

              <div className="changePasswordButton" onClick={() => changePassword()}>
                Change Password
              </div>
            </div>
          ) : (
            <>
              {forgotPasswordStatus ? (
                codeSentStatus ? (
                  <form className="changePasswordFields">
                    <div className="codeContainer">
                      <h2 className="verificationDescription">
                        We sent a Verification Code to the email associated with your account. Copy the code and paste it here to change
                        your password.
                      </h2>
                      <p className="emailID">Verification Code</p>
                      <input
                        id="codeInputField"
                        className="codeInput"
                        type="text"
                        placeholder="Verification Code"
                        value={code}
                        onChange={handleCode}
                      />
                    </div>

                    <div className="emailIDContainer">
                      <p className="emailID">New Password</p>
                      <input
                        className="newPasswordInput"
                        type="password"
                        placeholder="**********"
                        value={newPassword}
                        onChange={handleNewPassword}
                      />
                    </div>

                    {errorStatus ? <div className="errorBox">{errorMessage}</div> : <div className="errorPlaceholder"></div>}

                    <div className="loginButton" onClick={() => forgotPasswordSubmit()}>
                      Submit
                    </div>

                    <div className="resendButton" onClick={() => forgotPassword()}>
                      Resend Code
                    </div>

                    <p
                      className="cancelChangePassword"
                      onClick={() => {
                        setForgotPasswordStatus(false);
                        setCodeSentStatus(false);
                        setErrorStatus(false);
                        setNewPassword('');
                        setCode('');
                      }}
                    >
                      Cancel
                    </p>
                  </form>
                ) : (
                  <div className="forgotPasswordUsername">
                    <div className="emailIDContainerForgotPassword">
                      <p className="emailID">Forgot Password Username</p>

                      <input
                        id="emailInputField"
                        className="emailInput"
                        type="text"
                        placeholder="username"
                        value={userName}
                        onChange={handleUser}
                      />
                    </div>

                    {errorStatus ? <div className="errorBox">{errorMessage}</div> : <div className="errorPlaceholder"></div>}

                    <input type="submit" className="loginButton" value="Send Code" onClick={() => forgotPassword()}></input>

                    <p
                      className="cancelForgotPasswordButton"
                      onClick={() => {
                        setForgotPasswordStatus(false);
                        setErrorStatus(false);
                      }}
                    >
                      Cancel
                    </p>
                  </div>
                )
              ) : (
                <>
                  {logingIn ? (
                    <div className="loginFields">
                      <p>Logging you in</p>
                      <CircularProgress />
                    </div>
                  ) : (
                    <form className="loginFields">
                      <div className="emailIDContainer">
                        <p className="emailID">Username</p>
                        <input className="emailInput" type="text" placeholder="username" value={userName} onChange={handleUser} />
                      </div>

                      <div className="passwordContainer">
                        <p className="password">Password</p>
                        <Input
                          className="passwordInput"
                          sx={{
                            border: '1px solid #dddfe1',
                            boxShadow: 0,
                            paddingRight: '.5rem',
                            fontFamily: 'Gordita',
                          }}
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          inputProps={{ disableUnderline: true }}
                          placeholder="************"
                          name="password"
                          onChange={handlePassword}
                          disableUnderline={true}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          // label="Password"
                          // inputProps={{}}
                        />
                      </div>

                      {errorStatus ? <div className="errorBox">{errorMessage}</div> : <div className="errorPlaceholder"></div>}

                      <input type="submit" value="Login" className="loginButton" onClick={() => signIn()}></input>

                      <p className="accountCreation">
                        <div className="otherOptions">
                          {/* <div className="keepMeSignedInContainer">
                        </div> */}

                          <p
                            className="forgotPassword"
                            onClick={() => {
                              setForgotPasswordStatus(true);
                              setErrorStatus(false);
                            }}
                          >
                            Forgot Password?
                          </p>
                        </div>
                      </p>
                    </form>
                  )}
                </>
              )}
            </>
          )}
          {/* </div> */}

          {/* <p className="footerPrivacyPolicy">
            <a href="https://fersktech.com/privacy-policy/" rel="noreferrer" target="_blank">
              Privacy Policy
            </a>
          </p> */}

          {!matches && (
            <a href="https://fersktech.hubspotpagebuilder.com/ferskauditsupport" rel="noreferrer" target="_blank">
              <button className="helpButtonLogin"> Help &amp; Support</button>
            </a>
          )}

          <p className="privacyPolicy">
            <a href="https://fersktech.com/privacy-policy/" rel="noreferrer" target="_blank">
              Privacy Policy
            </a>
          </p>

          <p className="copyright">
            {new Date().getFullYear()} Copyright © &nbsp;
            <a href="https://fersktech.com/" rel="noreferrer" target="_blank">
              FerskTech
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
