import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';

import Upload from '../upload';
import StandardAuditScheduler from './standardAuditScheduler';
import ScheduleType from './standardAuditScheduleType';

// import CustomizeAudit from './standardAuditCustomizeAudit';
// import AlternateAudits from './alternateAudits';
// import StandardAuditFrequency from './standardAuditFrequency';

import './standardAuditModal.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface Modal {
  active;
  toggleModalStatus;
  updateAudits;
  setUploadStatus;
  setAuditID;
  refresh;
  triggerCheckErrors;
  passScheduleType;
}

const Modal = props => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentID, setCurrentID] = useState(0);
  const [enableAlternateAudits, setEnableAlternateAudits] = useState(false);
  const [displayAlternateAudits, setDisplayAlternateAudits] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [next, setNext] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [scheduleAudit, setScheduleAudit] = useState(false);
  const [oneTimeAudit, setOneTimeAudit] = useState(false);
  const [auditName, setAuditName] = useState('');
  const [date, setDate] = useState(0);
  const [startDate, setStartDate] = useState('2022-02-22');
  const [endDate, setEndDate] = useState('2022-02-23');
  const [fersk, setFersk] = useState(false);
  const [gbta, setGBTA] = useState(false);
  const [username, setUsername] = useState('');

  let file = null;

  const formatDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    let formatMonth = false;
    let formatDay = false;

    if (month + 1 < 10) {
      formatMonth = true;
    }
    if (day < 10) {
      formatDay = true;
    }

    return `${year}-${formatMonth ? `0${month + 1}` : month + 1}-${formatDay ? `0${day}` : day}`;
  };

  const handleUploadCallback = fileToUpload => {
    file = fileToUpload;
    setUploadFile(fileToUpload);
  };

  const handleSchedulingAudit = () => {
    setScheduleAudit(true);
    setOneTimeAudit(false);
  };

  const handleOneTimeAudit = () => {
    setScheduleAudit(false);
    setOneTimeAudit(true);
  };

  const handleDateChange = newDate => {
    setDate(newDate);
  };

  const handleStartDateChange = newStartDate => {
    setStartDate(newStartDate);
  };

  const handleEndDateChange = newEndDate => {
    setEndDate(newEndDate);
  };

  const handleFerskFormat = () => {
    setFersk(true);
    setGBTA(false);
  };

  const handleGBTAFormat = () => {
    setGBTA(true);
    setFersk(false);
  };

  const pullUserName = async () => {
    // @ts-ignore
    const providedUsername = await Auth.currentAuthenticatedUser()
      .then(user => {
        return user.username;
      })
      .catch(null);
    setUsername(providedUsername);
  };

  useEffect(() => {
    pullUserName();
  }, [props.auditID]);

  const startScheduledAudit = async () => {
    setCurrentPage(0);
    props.passScheduleType('Recurring');
    const data = JSON.stringify({
      createUserName: username,
      auditScheduleTypeName: 'RECURRING_AUDIT',
      definitionDate: formatDate(),
      auditName,
    });

    await axios({
      method: 'post',
      url: '/api/user/audit',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    }).then(response => {
      if (response.data) {
        if (response.data.id) {
          uploadScheduledAuditFile(response.data.id);
        }
      }
    });
  };

  async function uploadScheduledAuditFile(id) {
    const formData = new FormData();
    formData.append('file', uploadFile);
    await axios({
      method: 'post',
      url: '/api/user/audit/' + id + '/upload',
      data: formData,
    }).then(response => {
      setAuditSchedule(id);
    });
  }

  const setAuditSchedule = async id => {
    const data = JSON.stringify({
      startDate,
      endDate,
      dayOfMonth: date,
    });

    await axios({
      method: 'post',
      url: '/api/user/audit/' + id + '/schedule',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    }).then(response => {
      props.updateID();
      props.refresh();
    });
  };

  const handleAuditTriggerCallback = () => {
    props.toggleModalStatus();
    startOneTimeAudit();
  };

  const startOneTimeAudit = async () => {
    setCurrentPage(0);
    props.setUploadStatus(true);
    props.passScheduleType(null);

    const data = {
      createUserName: 'nathan',
      auditScheduleTypeName: 'ADHOC_AUDIT',
      definitionDate: formatDate(),
      auditName,
    };

    try {
      const formData = new FormData();
      formData.append('file', uploadFile);
      await axios({
        method: 'post',
        url: '/api/adhoc-audit-upload',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      }).then(response => {
        if (response.data) {
          changeName(auditName, response.data.id);
        }
      });
    } catch {
      props.setUploadStatus(false);
      alert('There was an error with your upload, please try uploading again');
    }
  };

  async function uploadOneTimeAuditFile(id) {
    const formData = new FormData();
    formData.append('file', uploadFile);
    await axios({
      method: 'post',
      url: '/api/user/audit/' + id + '/upload',
      data: formData,
    }).then(response => {
      runAudit(id);
    });
  }

  const runAudit = async id => {
    await axios({
      method: 'post',
      url: '/api/user/audit/' + id + '/run',
    }).then(response => {
      props.setAuditID(id);
      props.refresh();
    });
  };

  const nameChangeCallbackFunction = newName => {
    setAuditName(newName);
  };

  const changeName = async (name, id) => {
    const myURL = '/api/user/audit/' + id;
    const raw = JSON.stringify({
      id,
      auditName: name,
    });

    await axios(myURL, {
      method: 'PATCH',
      url: myURL,
      data: raw,
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).then(response => {
      if (response.status === 200) {
        setCurrentPage(0);
        props.setAuditID(id);
        props.refresh();
        props.triggerCheckErrors(id);
      }
    });
  };

  const nextPageStepper = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPageStepper = () => {
    if (enableAlternateAudits && currentPage === 1) {
      setEnableAlternateAudits(false);
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const pageStepper = page => {
    if (scheduleAudit) {
      switch (page) {
        case 0:
          return (
            <ScheduleType
              handleOneTimeAudit={() => handleOneTimeAudit()}
              handleSchedulingAudit={() => handleSchedulingAudit()}
              nextPage={nextPageStepper} /* enableNext={() => updateNext(true)} disableNext={() => updateNext(false)} */
            />
          );

        case 1:
          return (
            <Upload
              uploadCallbackFunction={handleUploadCallback}
              nameChangeCallbackFunction={nameChangeCallbackFunction}
              previousPage={previousPageStepper}
              blueButtonFunction={nextPageStepper}
              blueButtonText={'Run Audit'}
            />
          );

        case 2:
          return (
            <StandardAuditScheduler
              previousPage={previousPageStepper}
              toggleModal={props.toggleModalStatus}
              dateChange={handleDateChange}
              startDateChange={handleStartDateChange}
              endDateChange={handleEndDateChange}
              scheduleAuditKickoff={startScheduledAudit}
            />
          );

        default:
          return <Upload />;
      }
    } else {
      switch (page) {
        case 0:
          return (
            <ScheduleType
              handleOneTimeAudit={() => handleOneTimeAudit()}
              handleSchedulingAudit={() => handleSchedulingAudit()}
              nextPage={nextPageStepper} /* enableNext={() => updateNext(true)} disableNext={() => updateNext(false)} */
            />
          );

        case 1:
          return (
            <Upload
              uploadCallbackFunction={handleUploadCallback}
              nameChangeCallbackFunction={nameChangeCallbackFunction}
              blueButtonFunction={handleAuditTriggerCallback}
              toggleModal={props.toggleModalStatus}
              previousPage={previousPageStepper}
              blueButtonText={'Run Audit'}
            />
          );

        default:
          return <Upload />;
      }
    }
  };

  return (
    <div className={props.active ? 'activeModal' : 'modal'}>
      <div className="modalContainer">
        <div className="standardAuditCancelContainer">
          <span
            className="closeModal"
            onClick={() => {
              props.toggleModalStatus();
              setCurrentPage(0);
            }}
          >
            &times;
          </span>
        </div>

        <div className="modalContentContainer">{pageStepper(currentPage)}</div>
      </div>
    </div>
  );
};

export default Modal;
