import React, { useState, useEffect } from 'react';
import './footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <p className="footerPrivacyPolicy">
        <a href="https://fersktech.com/privacy-policy/" rel="noreferrer" target="_blank">
          Privacy Policy
        </a>
      </p>

      <p className="footerCopyright">
        2022 Copyright @{' '}
        <a href="https://fersktech.com/" rel="noreferrer" target="_blank">
          FerskTech
        </a>
      </p>
    </div>
  );
};

export default Footer;
