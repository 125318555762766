import React, { Component, useEffect, useState } from 'react';
import './auditChartFilters.scss';

import axios from 'axios';


// const Filter = ({ selectedMetric, onMetricChange }) => {

// let passedMetric = null;


const Filter = ({ onSendValue }) => {
  // const [selectedMetric, onMetricChange] = useState('audits');
  
  
  // const auditID = props

  // let auditID = null;
  // auditID = props.auditID;

  // I think I need to even remove that top part and just create the constant but will try the other way first

  // if (selectedMetric === 'audits') {
    // passedMetric = 'audits';  
  // } else if (selectedMetric === 'success') {
    // passedMetric = 'success';
  // } else if (selectedMetric === 'failure') {
    // passedMetric = 'failure';
  // } else if (selectedMetric === 'higher') {
    // passedMetric = 'higher';
  // } else {
    // passedMetric = 'audits';  
  // }

  // const handleMetricFilterClick = (type) => {
  //   onMetricChange(type);
  // };

  const handleClick = (value) => {
    onSendValue(value);
  };
  

  return (
    <div>
      <div className="filter">
        <button className="filter-button" onClick={() => handleClick('loaded')}>Rates Loaded</button>
        <button className="filter-button" onClick={() => handleClick('availability')}>Rate Availability</button>
        <button className="filter-button" onClick={() => handleClick('accuracy')}>Rate Accuracy</button>
        <button className="filter-button" onClick={() => handleClick('higher')}>Rates Loaded Higher</button>
      </div>
    </div>
  );
}

export default Filter;


      // <div>
        // <HorizontalBarChart1 selMetric={selectedMetric} />
      // </div>

// return (
  // <div>
    // <div className="filter">
      // <button onClick={() => handleMetricFilterClick('audits')}>Audits</button>
      // <button onClick={() => handleMetricFilterClick('success')}>Success</button>
      // <button onClick={() => handleMetricFilterClick('failure')}>Failure</button>
      // <button onClick={() => handleMetricFilterClick('higher')}>Rates Loaded Higher</button>
    // </div>
    // <div>
      // <HorizontalBarChart1 selMetric={selectedMetric} />
    // </div>
  // </div>
// );

  


// OLD VERSION THAT IS OVERLY COMPLICATED
// JUST NEED TO CREATE ONE VALUE FROM THIS FILTER AND THEN PASS THAT SINGLE VALUE TO THE CHARTS

//   const handleFilterChange = (event) => {
//     const newValue = event.target.value;
//     onMetricChange(newValue);
//     onFilterChange(newValue); // Notify parent component about the filter change
//   };
// 
//     return (
//       <div>
//         <button
//           className={selectedMetric === 'Audits' ? 'filter-button selected' : 'filter-button'}
//           onClick={() => onMetricChange('Audits')}
//         >
//           CHANGE BACK - Audits
//         </button>
//         <button
//           className={selectedMetric === 'Success' ? 'filter-button selected' : 'filter-button'}
//           onClick={() => onMetricChange('Success')}
//         >
//           CHANGE BACK - Success
//         </button>
//         <button
//           className={selectedMetric === 'Failure' ? 'filter-button selected' : 'filter-button'}
//           onClick={() => onMetricChange('Failure')}
//         >
//           CHANGE BACK - Failure
//         </button>
//         <button
//           className={selectedMetric === 'Impressions' ? 'filter-button selected' : 'filter-button'}
//           onClick={() => onMetricChange('Impressions')}
//         >
//           Rates Loaded Higher
//         </button>
//         </div>
//     );
//   };
  
//   export default Filter;



// THE VERSION THAT ACTUALLY WORKED
// const Filter = ({ selectedMetric, onMetricChange }) => {
  // return (
    // <div>
      // <button
        // className={selectedMetric === 'Audits' ? 'filter-button selected' : 'filter-button'}
        // onClick={() => onMetricChange('Audits')}
      // >
        // CHANGE BACK - Audits
      // </button>
      // <button
        // className={selectedMetric === 'Success' ? 'filter-button selected' : 'filter-button'}
        // onClick={() => onMetricChange('Success')}
      // >
        // CHANGE BACK - Success
      // </button>
      // <button
        // className={selectedMetric === 'Failure' ? 'filter-button selected' : 'filter-button'}
        // onClick={() => onMetricChange('Failure')}
      // >
        // CHANGE BACK - Failure
      // </button>
      // <button
        // className={selectedMetric === 'Impressions' ? 'filter-button selected' : 'filter-button'}
        // onClick={() => onMetricChange('Impressions')}
      // >
        // Rates Loaded Higher
      // </button>
      // </div>
  // );
// };

// export default Filter;





// option value="ratesLoaded">Rates Loaded</option>
//   <option value="rateAvailability">Rate Availability</option>
//   <option value="rateAccuracy">Rate Accuracy</option>
//   <option value="rateHigher">Rates Loaded Higher</option>

// ORIGINAL VERSION
// const chartMetricFilter = ({ onFilter }) => {
    // const handleFilterClick = (type) => {
      // onFilter(type);
    // };

  // return (
    // <div>
      // <button onClick={handleFilterClick('ratesLoaded')}>Rates Loaded</button>
      // <button onClick={handleFilterClick('rateAvailability')}>Rate Availability</button>
      // <button onClick={handleFilterClick('rateAccuracy')}>Rate Accuracy</button>
      // <button onClick={handleFilterClick('rateHigher')}>Rates Loaded Higher</button>
    // </div>
  // );
// };

// export default chartMetricFilter;
