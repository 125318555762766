import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';

// Imports from visx

import { Group } from '@visx/group';
import { scaleOrdinal } from '@visx/scale';
import { animated, useTransition, interpolate, update } from 'react-spring';

// Imports from Charts.js
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
Chart.register(ArcElement, Tooltip, Legend);

interface AuditsPieChart {
  auditID;
  failureDataLength;
}

export default function AuditsPieChart(props, { events = false }: { events?: boolean }) {
  const [failData, setFailData] = useState(1);
  const [chartData, setChartData] = useState({
    labels: [
      'Rate Not Loaded',
      'Rate Not Available',
      'Amenity Load Issue',
      'Rate Loaded Higher',
      'Rate Loaded Lower',
      'Cancellation Policy Issue',
      'Property Not Found',
    ],
    datasets: [
      {
        label: 'Audit Data',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: ['#022067', '#29A2BE', '#FD5274', '#FFC107', '#F37D26', '#673BB7', '#1894D1'],
        hoverOffset: 10,
      },
    ],
  });

  //   { name: 'Rate Not Loaded', value: 0, color: '#022067' },
  //   { name: 'Rate Not Available', value: 0, color: '#29A2BE' },
  //   { name: 'Amenity Load Issue', value: 0, color: '#FD5274' },
  //   { name: 'Rate Loaded Higher', value: 0, color: '#FFC107' },
  //   { name: 'Rate Loaded Lower', value: 0, color: '#F37D26' },
  //   { name: 'Cancellation Policy Issue', value: 0, color: '#673BB7' },
  //   { name: 'Property Not Found', value: 0, color: '#1894D1' },
  // ]);
  // const margin = { top: 20, right: 0, bottom: 20, left: 60 };
  // const width = 225;
  // const height = 225;

  // const innerWidth = width - margin.left - margin.right;
  // const innerHeight = height - margin.top - margin.bottom;
  // const radius = Math.min(innerWidth, innerHeight) / 2;
  // const centerY = innerHeight / 2;
  // const centerX = innerWidth / 2;
  // const donutThickness = 25;
  // const defaultMargin = { top: 20, right: 0, bottom: 0, left: 0 };

  // const legendGlyphSize = 15;

  useEffect(() => {
    if (props.auditID) {
      updateChartData();
    }
  }, [props.auditID]);

  const updateChartData = async () => {
    try {
      const summaryURL = 'api/user/audit/' + props.auditID + '/summary';
    const summaryData = await (await axios.get(summaryURL)).data;
    setFailData(summaryData.totalFailures);

    setChartData({
      labels: [
        'Rate Not Loaded',
        'Rate Not Available',
        'Amenity Load Issue',
        'Rate Loaded Higher',
        'Rate Loaded Lower',
        'Cancellation Policy Issue',
        'Property Not Found',
      ],
      datasets: [
        {
          label: 'Audit Data',
          data: [
            summaryData.rateNotLoaded + summaryData.rateMismatch,
            summaryData.rateNotFound + summaryData.rateNotLoaded,
            summaryData.amenityMismatch ? summaryData.amenityMismatch : 0,
            summaryData.rateLoadedHigher + summaryData.rateTooHigh,
            summaryData.rateLoadedLower ? summaryData.rateLoadedLower : 0,
            summaryData.cancellationMismatch ? summaryData.cancellationMismatch : 0,
            summaryData.hotelNotFound + summaryData.notAuditedError,
          ],
          backgroundColor: ['#022067', '#29A2BE', '#FD5274', '#FFC107', '#F37D26', '#673BB7', '#1894D1'],
          hoverOffset: 10,
        },
      ],
    });
    } catch (error) { 
       // eslint-disable-next-line no-console
    console.log('Error in PieChart:', error);
    }
    
  };

  const options = {
    maintainAspectRatio: false,
    normalized: true,
    responsive: true,
    layout: {
      padding: {
        bottom: 6,
        top: 3,
      },
    },
    plugins: {
      legend: {
        position: 'right' as const,
      },
      datalabels: {
        display: false,
      },
    },
  };

  // const plugins = [
  //   {
  //     id: 'plugins',
  //     beforeDraw(chart) {
  //       const ctx = chart.ctx;
  //       const fontSize = (chart.height / 160).toFixed(2);
  //       ctx.font = fontSize + 'em sans-serif';
  //       ctx.textBaseline = 'bottom';
  //       let text = "";
  //       if (failData === 1) {
  //         text = '   ' + failData + '\nFailure';
  //       } else if (failData < 10) {
  //         text = '     ' + failData + '\nFailures';
  //       } else if (failData < 100) {
  //         text = '    ' + failData + '\nFailures';
  //       } else if (failData < 1000) {
  //         text = '   ' + failData + '\nFailures';
  //       } else {
  //         text = '  ' + failData + '\nFailures';
  //       }
  //       const lineheight = 30;
  //       const lines = text.split('\n');
  //       const textWidth = ctx.measureText(text).width;
  //       for (let i = 0; i < lines.length; i++) {
  //         if (failData < 10) {
  //           ctx.fillText(lines[i], chart.width / 1.915 - textWidth / 2 - 84.8, chart.height - 125 + i * lineheight);
  //         } else if (failData < 100) {
  //           ctx.fillText(lines[i], chart.width / 2 - textWidth / 2 - 66.9, chart.height - 125 + i * lineheight);
  //         } else if (failData < 1000) {
  //           ctx.fillText(lines[i], chart.width / 2.01 - textWidth / 2 - 62.3, chart.height - 125 + i * lineheight);
  //         } else {
  //           ctx.fillText(lines[i], chart.width / 2.01 - textWidth / 2 - 59, chart.height - 125 + i * lineheight);
  //         }
  //       }
  //       ctx.save();
  //     },
  //   },
  // ];

  // { labels: 'Rate Not Loaded', value: summaryData.rateNotLoaded + summaryData.rateMismatch, color: '#002067' },
  // { name: 'Rate Not Available', value: summaryData.rateNotFound + summaryData.rateNotLoaded, color: '#29A2BE' },
  // { name: 'Amenity Load Issue', value: summaryData.amenityMismatch ? summaryData.amenityMismatch : 0, color: '#FD5274' },
  // { name: 'Rate Loaded Higher', value: summaryData.rateLoadedHigher + summaryData.rateTooHigh, color: '#FFC107' },
  // { name: 'Rate Loaded Lower', value: summaryData.rateLoadedLower ? summaryData.rateLoadedLower : 0, color: '#1894D1' },
  // {
  //   name: 'Cancellation Policy Issue',
  //   value: summaryData.cancellationMismatch ? summaryData.cancellationMismatch : 0,
  //   color: '#F37D26',
  // },
  // { name: 'Property Not Found', value: summaryData.hotelNotFound + summaryData.notAuditedError, color: '#673BB7' },

  // FIXME: make this more readable
  // const value = d => d.value;

  // const getLetterFrequencyColor = scaleOrdinal({
  //   domain: chartData.map(data => data.name),
  //   range: chartData.map(data => data.color),
  // });

  return (
    <div className="auditsPieChartContent">
      {chartData ? (
        <div className="auditsPieChartContent">
          {/* <svg width={width} height={height}> */}
          {/* <Group top={centerY + margin.top} left={centerX + margin.left}> */}
          <Pie data={chartData} height={'220rem'} options={options} />
          {/* // pieValue={value}
                // outerRadius={radius}
                // innerRadius={radius - donutThickness}
                // cornerRadius={3}
                // padAngle={0.005} */}
          {/* {pie => {
                  return pie.arcs.map((arc, index) => {
                    const { name } = arc.data;
                    const [centroidX, centroidY] = pie.path.centroid(arc);
                    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
                    const arcPath = pie.path(arc);
                    const arcFill = getLetterFrequencyColor(name);
                    return (
                      <g key={`arc-${name}-${index}`}>
                        <path d={arcPath} fill={arcFill} />
                      </g>
                    );
                  });
                }} */}
          {/* <text textAnchor="middle" y="0" fill={'#002067'} fontSize={'32'}>
                {props.failureDataLength ? props.failureDataLength : 0}
              </text>
              <text textAnchor="middle" y="15" fontSize={'12'} fill={'#6A707E'}>
                Total Failures
              </text> */}
          {/* </Group> */}
          {/* </svg> */}

          {/* <div className="pieChartLegend">
            <div className="pieChartLegendRowContainer">
              <div className="pieChartLegendRow">
                <div className="pieChartLegendCircle" style={{ backgroundColor: '#002067' }}></div>
                <p>{chartData[0].datasets} Rate not loaded</p>
              </div>
              <div className="pieChartLegendRow">
                <div className="pieChartLegendCircle" style={{ backgroundColor: '#29A2BE' }}></div>
                <p>{chartData[1].datasets} Rate not available</p>
              </div>
              <div className="pieChartLegendRow">
                <div className="pieChartLegendCircle" style={{ backgroundColor: '#FD5274' }}></div>
                <p>{chartData[2].datasets} Amenity load issue</p>
              </div>
              <div className="pieChartLegendRow">
                <div className="pieChartLegendCircle" style={{ backgroundColor: '#FFC107' }}></div>
                <p>{chartData[3].datasets} Rate loaded higher</p>
              </div>
              <div className="pieChartLegendRow">
                <div className="pieChartLegendCircle" style={{ backgroundColor: '#1894D1' }}></div>
                <p>{chartData[4].datasets} Rate loaded lower</p>
              </div>
              <div className="pieChartLegendRow">
                <div className="pieChartLegendCircle" style={{ backgroundColor: '#F37D26' }}></div>
                <p>{chartData[5].datasets} Cancel policy issue</p>
              </div>
              <div className="pieChartLegendRow">
                <div className="pieChartLegendCircle" style={{ backgroundColor: '#673BB7' }}></div>
                <p>{chartData[6].datasets} Property not found</p>
              </div>
            </div>
          </div> */}
        </div>
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
  );
}
