import axios from 'axios';
import { Auth } from 'aws-amplify';
import { from } from 'rxjs/observable/from';

import { Link, withRouter } from 'react-router-dom';

import { SERVER_API_URL } from 'app/config/constants';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = async config => {
    await Auth.currentSession()
      .then(auth => {
        const token = auth.getIdToken().getJwtToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      })
      .catch(err => {});

    return config;
  };

  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403) {
      onUnauthenticated();
    }
    if (status === 401) {
      window.location.replace('/');
      onUnauthenticated();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
