import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import { FirstProvider } from './store/firstContext';

// imported components
import Nav from 'app/shared/layout/navigation/Nav';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import Drawer from 'app/shared/layout/drawer/drawer';
import ProgramOverview from './modules/ProgramOverview/programoverview';
import Audits from './modules/Audits/audits';
import ProgramAnalytics from './modules/ProgramAnalytics/programAnalytics';
import Login from './modules/login/login';
import PropertiesNeedingAction from './modules/ManageRateLoadErrors/manageRateLoadErrors';
import { UserProvider } from 'app/userContext';
// import MarketReport from './modules/MarketReport/marketReport';

// imported style sheets
import './app.scss';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <UserProvider>
            <Route path="/" exact component={Login} />

            <Route path="/programOverview">
              <header>
                <Header />
              </header>
              <Drawer />
              <div className="Window">
                <ProgramOverview />
              </div>
              <footer>
                <Footer />
              </footer>
            </Route>

            <Route path="/audits">
              <header>
                <Header />
              </header>
              <Drawer />
              <div className="Window">
                <Audits />
              </div>
              <footer>
                <Footer />
              </footer>
            </Route>

            <Route path="/propertiesneedingaction">
              <header>
                <Header />
              </header>
              <Drawer />
              <div className="Window">
                <PropertiesNeedingAction />
              </div>
              <footer>
                <Footer />
              </footer>
            </Route>

            <Route path="/marketReport">
              <header>
                <Header />
              </header>
              <Drawer />
              <div className="Window">{/* <MarketReport />*/}</div>
              <footer>
                <Footer />
              </footer>
            </Route>
            {/* <Route path="/audits" component={Audits} /> */}
            {/* <Route path="/programanalytics" component={ProgramAnalytics} /> */}
            {/* <Route component={PageNotFound} /> */}
            {/* <Route path="/propertiesneedingaction" component={PropertiesNeedingAction} /> */}
          </UserProvider>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
