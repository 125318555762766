import React, { useState, useEffect } from 'react';
import axios from 'axios';

// style import
import './note.scss';

interface Note {
  note;
  user;
  timeStamp;
  key;
  getNotes;
  id;
  openDeleteModal;
}

const Note = props => {
  const [formatedTimeStamp, setFormatedTimeStamp] = useState(null);
  const [editStatus, setEditStatus] = useState(false);
  const [editedNote, setEditedNote] = useState('');

  useEffect(() => {
    formatTimeStamp();
  });

  const formatMonth = monthCode => {
    switch (monthCode) {
      case '01':
        return 'January';
      case '02':
        return 'February';
      case '03':
        return 'March';
      case '04':
        return 'April';
      case '05':
        return 'May';
      case '06':
        return 'June';
      case '07':
        return 'July';
      case '08':
        return 'August';
      case '09':
        return 'September';
      case '10':
        return 'October';
      case '11':
        return 'November';
      case '12':
        return 'December';
      default:
        return monthCode;
    }
  };

  const formatTime = (hour, minute) => {
    if (hour < 12) {
      return `${hour}:${minute}am`;
    } else {
      return `${hour - 12}:${minute}pm`;
    }
  };

  const formatTimeStamp = () => {
    const newArray = props.timeStamp.split('-');
    const month = formatMonth(newArray[1]);
    const dateTimeArray = newArray[2].split('T');
    const day = dateTimeArray[0];
    const timeArray = dateTimeArray[1].split(':');
    const time = formatTime(timeArray[0], timeArray[1]);
    const year = newArray[0];

    setFormatedTimeStamp(`${month} ${day}, ${year} ${time}`);
  };

  const handleEditedNote = event => {
    setEditedNote(event.target.value.replace(/(?:\r\n|\r|\n)/g, ''));
  };

  const editNote = () => {
    if (editedNote) {
      axios(`/api/user/note/${props.id}`, {
        method: 'PATCH',
        data: {
          id: props.id,
          note: editedNote,
        },
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      }).then(response => {
        if (response.status === 200) {
          setEditStatus(false);
          props.getNotes();
        }
      });
    } else {
      props.openDeleteModal(props.id);
    }
  };

  return (
    <div className="note">
      <div className="noteContent">
        <div className="noteDescription">
          <div className="userIcon">
            <img className="emailPicture" src="../../../../../content/images/FersktechLogo.svg" alt="" />
          </div>
          <p className="notesUserName">{props.user}</p>
          <p>added a</p>
          <p className="noteType">Note</p>
          <p className="notesTimestamp">{formatedTimeStamp}</p>
        </div>

        {editStatus ? (
          <div className="editNoteContainer">
            <div className="editNoteBoxContainer">
              <textarea className="editNoteBox" defaultValue={props.note} onChange={handleEditedNote} />
            </div>
            <div className="editNoteButtonContainer">
              <button className="editCancelButton" onClick={() => setEditStatus(false)}>
                cancel
              </button>
              <button className="editSaveButton" onClick={() => editNote()}>
                save
              </button>
            </div>
          </div>
        ) : (
          <div className="noteSectionContainer">
            <li className="noteParagraph">{props.note}</li>
            <div className="editDelete">
              <p className="editDeleteButton" onClick={() => setEditStatus(true)}>
                Edit
              </p>
              <div className="divider"></div>
              <p className="editDeleteButton" onClick={() => props.openDeleteModal(props.id)}>
                Delete
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Note;
