import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const defaultValue = {
  currentUser: '',
  currentUserEmail: '',
};

const UserContext = createContext(defaultValue);

// interface UserProvider {
//   currentUser: string;
//   currentUserEmail: string;
// }

export function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState('nathan');
  const [currentUserEmail, setCurrentUserEmail] = useState('');

  useEffect(() => {
    pullUserName();
  }, []);

  const pullUserName = async () => {
    // @ts-ignore
    await Auth.currentAuthenticatedUser()
      .then(user => {
        setCurrentUser(user.username);
        setCurrentUserEmail(user.attributes.email);
      })
      .catch(null);
  };

  return <UserContext.Provider value={{ currentUser, currentUserEmail }}>{children}</UserContext.Provider>;
}

export default UserContext;
