import React, { useState, useEffect, useContext } from 'react';
import UserContext from 'app/userContext';
import axios from 'axios';

// local components import
import Note from './note/note';
import DeleteModal from './delete/deleteModal';
import ConfirmationModal from './confirmation/confirmation';

import './notesModal.scss';

import { Tooltip } from '@material-ui/core';

interface NotesModal {
  active;
  toggleModal;
  detailsID;
}

const NotesModal = props => {
  const [note, setNote] = useState('');
  const [allNotes, setAllNotes] = useState(null);
  const [currentNoteID, setCurrentNoteID] = useState(null);
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [confirmationModalStatus, setConfirmationModalStatus] = useState(false);
  const [invalidNote, setInvalidNote] = useState(false);
  const [saveStatus, setSaveStatus] = useState(false);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (props.active === true) {
      getNotes();
    }
  }, [props.active]);

  const handleNoteChange = event => {
    setNote(event.target.value.replace(/(?:\r\n|\r|\n)/g, ''));
    if (event.target.value === '') {
      setSaveStatus(false);
    } else {
      setSaveStatus(true);
    }
  };

  const addNote = () => {
    if (note) {
      axios
        .post(`/api/user/${props.detailsID}/addnote`, {
          note,
        })
        .then(response => {
          if (response.status === 201) {
            clearNote();
            getNotes();
          }
        });
    } else {
      setInvalidNote(true);
    }
  };

  const saveAndExit = () => {
    axios
      .post(`/api/user/${props.detailsID}/addnote`, {
        note,
      })
      .then(response => {
        if (response.statusText === 'Created') {
          clearNote();
          props.toggleModal();
          setConfirmationModalStatus(false);
        }
      });
  };

  const exitModal = () => {
    props.toggleModal();
    clearNote();
    setConfirmationModalStatus(false);
  };

  const clearNote = () => {
    setNote('');
  };

  const getNotes = async () => {
    if (props.detailsID) {
      const notesData = await (await axios.get(`/api/user/${props.detailsID}/notes`)).data;
      if (notesData) {
        if (notesData._embedded) {
          if (notesData._embedded.hotelAuditPropertyNoteList) {
            setAllNotes(notesData._embedded.hotelAuditPropertyNoteList);
          } else {
            setAllNotes(null);
          }
        } else {
          setAllNotes(null);
        }
      } else {
        setAllNotes(null);
      }
    }
  };

  const deleteNote = () => {
    axios.delete(`/api/user/note/${currentNoteID}`).then(response => {
      if (response.status === 202) {
        getNotes();
        setDeleteModalStatus(false);
      }
    });
  };

  const cancelDeleteNote = () => {
    setDeleteModalStatus(false);
  };

  const openDeleteModal = noteID => {
    setDeleteModalStatus(true);
    setCurrentNoteID(noteID);
  };

  return (
    <div className={props.active ? 'notesModalActive' : 'notesModal'}>
      <div className={allNotes ? 'notesContentArea' : 'notesContentAreaShrunk'}>
        <DeleteModal cancel={() => cancelDeleteNote()} status={deleteModalStatus} deleteNote={() => deleteNote()} />
        <ConfirmationModal
          exit={() => exitModal()}
          status={confirmationModalStatus}
          saveAndExit={() => saveAndExit()}
          class={allNotes ? 'confirmationModalActive' : 'confirmationModalActiveShrunk'}
        />

        <div className="notesTitleContainer">
          <span
            className="closeNotesModal"
            onClick={() => {
              if (note) {
                setConfirmationModalStatus(true);
              } else {
                props.toggleModal();
              }
            }}
          >
            &times;
          </span>
        </div>

        <div className="NoteAreaModal">
          <h1 className="notesHeading">Take Notes</h1>
          <div className="notesSection">
            <div>
              <div className="userIcon">
                <img className="emailPicture" src="../../../../content/images/FersktechLogo.svg" alt="" />
              </div>
            </div>
            {invalidNote ? (
              <textarea
                className="noteBoxInvalid"
                onClick={() => setInvalidNote(false)}
                value={'Oops! It looks like you tried to save a blank note!'}
                onChange={handleNoteChange}
              />
            ) : (
              <textarea className="noteBox" value={note} onChange={handleNoteChange} />
            )}
          </div>

          <div className="notesButtonBox">
            <button className="cancel" onClick={() => exitModal()}>
              cancel
            </button>
            {saveStatus ? (
              <button className="save" onClick={() => addNote()}>
                save
              </button>
            ) : (
              <Tooltip title={`Can't save a blank note`}>
                <button className="saveDisabled">save</button>
              </Tooltip>
            )}
          </div>
        </div>

        {allNotes ? (
          <div>
            <h2 className="notesSubtitle">Activity</h2>
            <div className="previousActivity">
              {allNotes ? (
                allNotes.map(data => {
                  return (
                    <Note
                      note={data.note}
                      user={currentUser}
                      timeStamp={data.lastModifiedDate}
                      key={data.id}
                      getNotes={() => getNotes()}
                      id={data.id}
                      openDeleteModal={openDeleteModal}
                    />
                  );
                })
              ) : (
                <div></div>
              )}
            </div>
          </div>
        ) : (
          <div className="noPreviousActivity"></div>
        )}
      </div>
    </div>
  );
};

export default NotesModal;
