import React, { useState, useEffect } from 'react';

import CalendarPicker from '@mui/lab/CalendarPicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import './standardAuditScheduler.scss';

interface StandardAuditScheduler {
  previousPageStepper;
  toggleModal;
  dateChange;
  startDateChange;
  endDateChange;
  scheduleAuditKickoff;
}

const formatDate = newDate => {
  const dateArray = newDate.toString().split(' ');
  const year = dateArray[3];
  const month = monthFinder(dateArray[1]);
  const day = dateArray[2];
  return `${year}-${month}-${day}`;
};

const monthFinder = month => {
  switch (month) {
    case 'Jan':
      return '01';
    case 'Feb':
      return '02';
    case 'Mar':
      return '03';
    case 'Apr':
      return '04';
    case 'May':
      return '05';
    case 'Jun':
      return '06';
    case 'Jul':
      return '07';
    case 'Aug':
      return '08';
    case 'Sep':
      return '09';
    case 'Oct':
      return '10';
    case 'Nov':
      return '11';
    case 'Dec':
      return '12';
    default:
      return '01';
  }
};

const StandardAuditStartDateScheduler = props => {
  const [startDate, setStartDate] = useState(formatDate(new Date()));
  const [endDate, setEndDate] = useState(formatDate(new Date()));
  const [dayOfMonth, setDayOfMonth] = useState(new Date().getDate());
  const [dayOfWeek, setDayOfWeek] = useState('Sunday');
  const [weekOfMonth, setWeekOfMonth] = useState('1st');
  const [endDateError, setEndDateError] = useState(false);

  useEffect(() => {
    // setDayOfWeek(dayOfWeekFinder(startDate.getDay()));
    // setWeekOfMonth(weekOfMonthFinder(dayOfMonth, startDate.getDay()));
    props.dateChange(dayOfMonth);
    props.startDateChange(startDate);
    props.endDateChange(endDate);
  }, []);

  const handleNewStartDate = newStartDate => {
    const newStartDateArray = newStartDate.split('-');
    props.startDateChange(newStartDate);
    setStartDate(newStartDate);
    props.dateChange(newStartDateArray[2]);
    setDayOfMonth(parseInt(newStartDateArray[2], 10));
    // setDayOfWeek(dayOfWeekFinder(newStartDate.getDay()));
    // setWeekOfMonth(weekOfMonthFinder(newStartDate.getDate(), newStartDate.getDay()));
  };

  const handleNewEndDate = newEndDate => {
    props.endDateChange(newEndDate);
    setEndDate(newEndDate);
  };

  const scheduleAudit = () => {
    if (endDate <= startDate) {
      setEndDateError(true);
    } else {
      props.scheduleAuditKickoff();
      props.toggleModal();
    }
  };

  // const weekOfMonthFinder = (day, weekDay) => {
  //   // FIXME!!!!!!!
  //   const full = day + 6 - (weekDay - 1);
  //   const result = Math.ceil(full / 7);
  //
  //   switch (result) {
  //     case 1:
  //       return result + 'st';
  //     case 2:
  //       return result + 'nd';
  //     case 3:
  //       return result + 'rd';
  //     case 4:
  //       return result + 'th';
  //     case 5:
  //       return result + 'th';
  //     default:
  //       return result + 'xx';
  //   }
  // };

  const dayOfWeekFinder = date => {
    switch (date) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return 'Sunday';
    }
  };

  const returnOptions = () => {
    const myArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

    return myArray.map(num => (
      <option className="auditOptions" value={num} key={num}>
        {num}
      </option>
    ));
  };

  return (
    <div className="standardAuditScheduler">
      <div className="modalHeader">
        <h1 className="modalTitle">Schedule a Recurring Audit</h1>
      </div>
      <div className="modalContent">
        <div className="inputGroup">

          <p className="label">Start Date</p>
          <input
            className="textField"
            name="start"
            type="date"
            value={startDate}
            onChange={event => {
              handleNewStartDate(event.target.value);
            }}
          />

        </div>
        <div className="dayRadioButtonContainer">
          <label className="label" htmlFor="day">
            Run audit on day{' '}
            <select
              className="selectDayOfMonth"
              value={dayOfMonth}
              onChange={event => {
                setDayOfMonth(parseInt(event.target.value, 10));
                props.dateChange(parseInt(event.target.value, 10));
              }}
            >
              {returnOptions()}
            </select>{' '}
            of each month
          </label>
        </div>

        {/*  <div className="dayRadioButtonContainer">*/}
        {/*    <input className="input" type="radio" id="week" name={'runTime'} />*/}
        {/*    <label className="label" htmlFor="week">*/}
        {/*      On 1st week of month*/}
        {/*    </label>*/}
        {/*  </div>*/}
        {/* </form>*/}
        <div className="inputGroup">

          <p className="label">End Date</p>
          <input
            className={endDateError ? 'endDateInputError' : 'textField'}
            type="date"
            value={endDate}
            onChange={event => {
              handleNewEndDate(event.target.value);
            }}
            onClick={() => setEndDateError(false)}
          />
          {endDateError ? (
            <p className="endDateErrorMessage">End date cannot be before start date</p>
          ) : (
            <div className="endDateErrorMessagePlaceholder"></div>
          )}

        </div>
      </div>

      <div className="schedulerButtonContainer">
        <button className="button button-transparent" onClick={() => props.previousPage()}>
          <p className='button-text'>Previous</p>
        </button>
        <button className="button button-filled" onClick={() => scheduleAudit()}>
          <p className='button-text'>Schedule Audit</p>
          
        </button>
      </div>
    </div>
  );
};

export default StandardAuditStartDateScheduler;
