import React, { useState } from 'react';

import './standardAuditScheduleType.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus, faFolder } from '@fortawesome/free-solid-svg-icons';

interface Upload {
  enableNext;
  disableNext;
  handleSchedulingAudit;
  handleOneTimeAudit;
  nextPage;
}

const ScheduleType = props => {
  const [scheduleAudit, setScheduleAudit] = useState(false);
  const [oneTimeAudit, setOneTimeAudit] = useState(false);

  return (
    <div className="auditScheduleType">
      <div className="modalHeader">
        <h1 className="modalTitle">Run an Audit</h1>
        
      </div>

      <div className="descriptionContainer">
        <h3 className="standardAuditDescription">
          Our Standard Audit will validate your company&apos;s negotiated hotel rate, cancellation policy and amenities are loaded into the
          GDS. Run audit now or schedule an audit to be run at a later date.
        </h3>
      </div>
      
      <div className="scheduleTypeButtonContainer">
        <button
          className={scheduleAudit ? 'button button-filled' : 'button button-filled'}
          onClick={() => {
            props.handleSchedulingAudit();
            setScheduleAudit(!scheduleAudit);
            setOneTimeAudit(false);
            props.nextPage();
          }}
        >
        
          <p className="button-text">Schedule Recurring Audit</p>
        </button>
        <button
          className={oneTimeAudit ? 'button button-outline' : 'button button-outline'}
          onClick={() => {
            props.handleOneTimeAudit();
            setOneTimeAudit(!oneTimeAudit);
            setScheduleAudit(false);
            props.nextPage();
          }}
        >
          <p className="button-text">One Time Audit</p>
        </button>
        
      </div>
    </div>
  );
};

export default ScheduleType;
