import React, { useContext, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

import './deleteAuditConfirmation.scss';

interface DeleteAuditConfirmation {
  active;
  toggleModalStatus;
  auditID;
  overRideName;
  updateID;
  refreshAudits;
  currentAuditID;
  passUploadStatus;
}

const DeleteAuditConfirmation = props => {
  const [deleting, setDeleteing] = useState(false);

  function deleteMethod() {
    axios.delete('/api/user/audit/' + props.auditID + '/?overrideName=' + props.overRideName).then(function (response) {
      if (response.status === 202) {
        if (props.auditID === props.currentAuditID) {
          props.passUploadStatus(false);
        }
        props.updateID();
        props.refreshAudits();
        props.toggleModalStatus();
        setDeleteing(false);
      }
    });
  }

  return (
    <div className={props.active ? 'activeDeleteModal' : 'deleteModal'}>
      <div className="deleteModalContainer">
        <div className="standardAuditCancelContainer">
          <span
            className="closeDeleteModal"
            onClick={() => {
              props.toggleModalStatus();
            }}
          >
            &times;
          </span>
        </div>

        <div className="deleteModalContentContainer">
          {deleting ? (
            <div className={'deletingAuditIndicator'}>
              <h1 className="deletingAuditSpinnerTitle">Deleting Your Audit</h1>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <h1 className="deleteAuditTitle">Are you sure you want to delete this audit?</h1>
              <h6 className="deleteAuditName">Audit Name: {props.overRideName}</h6>
              <div className="deleteButtonContainer">
                <button className="cancelDelete" onClick={() => props.toggleModalStatus()}>
                  Cancel
                </button>
                <button
                  className="deleteConfirmation"
                  onClick={() => {
                    setDeleteing(true);
                    deleteMethod();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteAuditConfirmation;
