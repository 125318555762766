import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

// import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
// import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
// import register, { RegisterState } from 'app/modules/account/register/register.reducer';
// import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
// import password, { PasswordState } from 'app/modules/account/password/password.reducer';
// import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
// import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
// import hotelProperty, {
//   HotelPropertyState
// }
// }
// from 'app/entities/hotel-property/hotel-property.reducer';
// prettier-ignore
// import hotelProgram, {
//   HotelProgramState
// } from 'app/entities/hotel-program/hotel-program.reducer';
// // prettier-ignore
// import audit, {
//   AuditState
// } from 'app/entities/audit/audit.reducer';
// // prettier-ignore
// import auditResultsSummary, {
//   AuditResultsSummaryState
// } from 'app/entities/audit-results-summary/audit-results-summary.reducer';
// // prettier-ignore
// import auditFile, {
//   AuditFileState
// } from 'app/entities/audit-file/audit-file.reducer';
// // prettier-ignore
// import customer, {
//   CustomerState
// } from 'app/entities/customer/customer.reducer';
// // prettier-ignore
// import hotelAuditPropertyDetails, {
//   HotelAuditPropertyDetailsState
// } from 'app/entities/hotel-audit-property-details/hotel-audit-property-details.reducer';
// // prettier-ignore
// import hotelAuditPropertyHistory, {
//   HotelAuditPropertyHistoryState
// } from 'app/entities/hotel-audit-property-history/hotel-audit-property-history.reducer';
// prettier-ignore
// import program, {
//   ProgramState
// }
// from 'app/entities/program/program.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  // readonly administration: AdministrationState;
  // readonly userManagement: UserManagementState;
  // readonly register: RegisterState;
  // readonly activate: ActivateState;
  // readonly passwordReset: PasswordResetState;
  // readonly password: PasswordState;
  // readonly settings: SettingsState;
  // readonly hotelProperty: HotelPropertyState;
  // // readonly hotelProgram: HotelProgramState;
  // readonly audit: AuditState;
  // readonly auditResultsSummary: AuditResultsSummaryState;
  // readonly auditFile: AuditFileState;
  // readonly customer: CustomerState;
  // readonly hotelAuditPropertyDetails: HotelAuditPropertyDetailsState;
  // readonly hotelAuditPropertyHistory: HotelAuditPropertyHistoryState;
  // readonly program: ProgramState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  // administration,
  // userManagement,
  // register,
  // activate,
  // passwordReset,
  // password,
  // settings,
  // hotelProperty,
  // hotelProgram,
  // audit,
  // auditResultsSummary,
  // auditFile,
  // customer,
  // hotelAuditPropertyDetails,
  // hotelAuditPropertyHistory,
  // program,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
});

export default rootReducer;
