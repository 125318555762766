import React from 'react';

import './confirmation.scss';

interface DeleteModal {
  exit;
  status;
  saveAndExit;
  class;
}

const ConfirmationModal = props => {
  return (
    <div className={props.status ? props.class : 'confirmationModalInactive'}>
      <div className="confirmationBox">
        <div>
          <h1 className="confirmationTitle">Save Note Before Leaving?</h1>
        </div>
        <div className="exitSaveContainer">
          <button className="exitButton" onClick={() => props.exit()}>
            exit
          </button>
          <button className="saveButton" onClick={() => props.saveAndExit()}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
