import React, { useState, useEffect, useContext } from 'react';
import UserContext from 'app/userContext';

// Style Sheet Import
import './emailModal.scss';

import copy from 'copy-to-clipboard';

interface EmailModal {
  active;
  programName;
  toggleModal;
  data;
}

const copyEmail = () => {
  const message = document.getElementById('emailTemplate');
  copy(message.innerHTML, {
    format: 'text/html',
  });
};

const EmailModal = props => {
  const [copied, setCopied] = useState(false);
  const [hotelName, setHotelName] = useState(null);
  const [streetAddress, setStreetAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [stateProvince, setStateProvince] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [gdsPropertyID, setGdsPropertyID] = useState(null);
  const [auditDate, setAuditDate] = useState(null);
  const [rateFailures, setRateFailures] = useState(null);
  const [ratesAudited, setRatesAudited] = useState(null);
  const [rateCode, setRateCode] = useState(null);
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [destinationEmail, setDestinationEmail] = useState(null);
  const { currentUser, currentUserEmail } = useContext(UserContext);
  const [programName, setProgramName] = useState(null)
  const [errorData, setErrorData] = useState(null);  

  const awsEmailFunction = hotelEmail => {
    setSending(true);
    // console.log("This will eventually send an email via AWS SES");
    fetch('https://7sy4qai175.execute-api.us-east-2.amazonaws.com/sendEmail', {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        senderEmail: currentUserEmail,
        recipientEmail: hotelEmail,
        message: 'This is a test email',
        subject: 'New message from FerskAudit',
        defaultProgramName: props.programName,
        gds: 'Sabre',
        hotelStreetAddress: streetAddress,
        hotelCity: city,
        hotelName,
        rateCode,
        propertyID: gdsPropertyID,
        propertyErrors: rateFailures,
        auditDates: auditDate,
        ratesAudited,
        hotelEmail: 'hotel Email',
      }),
    });
    setSent(true);
    setSending(false);
  };



  // const form = useRef();

  // const sendEmail = hotelEmailAddress => {
  //   // e.preventDefault();
  //
  //   const templateParams = {
  //     program_name: props.programName,
  //     reply_to: 'emailhere@email.com',
  //     gds: 'Sabre',
  //     hotel_street_address: streetAddress,
  //     hotel_city: city,
  //     hotel_name: hotelName,
  //     rate_code: rateCode,
  //     property_id: gdsPropertyID,
  //     property_errors: rateFailures,
  //     audit_dates: auditDate,
  //     rates_audited: ratesAudited,
  //     hotel_email: hotelEmailAddress,
  //   };

  // emailjs.send('service_vnqkl1s', 'template_uwlxizf', templateParams, 'OiX7sq4I5Mfux9tXh').then(
  //   result => {
  //     setSent(true);
  //     setSending(false);
  //   },
  //   error => {
  //     alert(error.text);
  //     setSending(false);
  //   }
  // );
  // };

  useEffect(() => {
    extractData();
  });

  const extractData = () => {
    if (props.data) {
      if (props.data.row) {
        const propertyData = props.data.row.propertyData;
        if (propertyData) {
          if (propertyData.propertyHotelName) {
            setHotelName(propertyData.propertyHotelName);
          }
          if (propertyData.streetAddress) {
            setStreetAddress(propertyData.streetAddress);
          }
          if (propertyData.city) {
            setCity(propertyData.city);
          }
          if (propertyData.stateProvince) {
            setStateProvince(propertyData.stateProvince);
          }
          if (propertyData.postalCode) {
            setPostalCode(propertyData.postalCode);
          }
          if (propertyData.gdspropertyID) {
            setGdsPropertyID(propertyData.gdspropertyID);
          }
          if (propertyData.auditDate) {
            setAuditDate(propertyData.auditDate);
          }
          if (propertyData.propertyAuditFailure) {
            if (propertyData.propertyAuditFailure.description) {
              setRateFailures(propertyData.propertyAuditFailure.description);
            }
          }
          if (propertyData.rate1StaticRoomRateAmount) {
            setRatesAudited(propertyData.rate1StaticRoomRateAmount);
          }
          if (propertyData.rateCode) {
            setRateCode(propertyData.rateCode);
          }
        }
      }
    }
  };

  

  
  return (
    <div className={props.active ? 'emailModalActive' : 'emailModal'}>
      <div className="emailContentArea">
        <div className="emailTitleContainer">
          <span
            className="closeEmailModal"
            onClick={() => {
              props.toggleModal(null);
              setCopied(false);
            }}
          >
            &times;
          </span>
        </div>

        <div className="emailContentContainer" id="emailContentContainer">
          <h1 className="emailTitle">Contact Supplier</h1>
          <h2 className="emailHeading">Property Information</h2>
          <p className="emailInformation">{hotelName ? hotelName : 'Missing Hotel Name'}</p>
          <p className="emailInformation">{streetAddress ? streetAddress : 'Missing Street'}</p>
          <p className="emailInformation">
            {`${city ? city : 'Missing City'},
            ${stateProvince ? stateProvince : 'Missing State/Province'},
            ${postalCode ? postalCode : 'Missing Postal Code'}`}
          </p>
          <h2 className="emailHeading">Email Template</h2>
          <div className="emailTemplate" id="emailTemplate">
            <p className="emailTopParagraph">
              We have conducted a rate audit of the <b>{programName ? programName : (props.defaultProgramName ? props.defaultProgramName: 'Missing Program Name') }</b> hotel program.
              Unfortunately, we discovered one or more errors in the rate loaded by {hotelName ? hotelName : 'Missing'}
              {', '}
              {streetAddress ? streetAddress : 'Missing Street'}
            </p>
            <br />
            <p className="summaryOfErrors">The errors identified are outlined below:</p>

            {/* <ul className="errorList">
              {rateFailures ? (
                <li>
                  <p>{rateFailures}</p>
                </li>
              ) : (
                <li>
                  <p>No errors found</p>
                </li>
              )}
            </ul> */}
            
            <table className="errorTable">
              <thead>
                <th scope="col">Date Shopped</th>
                <th scope="col">Audit Name</th>
                <th scope="col">Rate Expected</th>
                <th scope="col">Rate Found</th>
                <th scope="col">Cancellation Expected</th>
                <th scope="col">Cancellation Found</th>
                <th scope="col">Amenity Icons</th>
              </thead>
              <tbody>
                <tr>
                  <td>Test Data</td>
                  <td>Test Data</td>
                  <td>Test Data</td>
                  <td>Test Data</td>
                  <td>Test Data</td>
                  <td>Test Data</td>
                  <td>Test Data</td>
                  
                </tr>
              </tbody>
            </table>

            <p className="lowerDescription">
              Please respond once you have corrected the errors. We will re-audit your property upon notification of correction, or 10 days
              from today’s date to ensure the errors have been corrected.
            </p>
            <br />
            <p className="auditsListLabel">As a courtesy, here are the parameters of the audit:</p>

            <h2 className="companyName">
              <strong>{hotelName ? hotelName : 'Missing Hotel Name'}</strong>
            </h2>

            <ul className="propertyDetailsList">
              <li>
                <p>
                  <strong>GDS: </strong>Sabre
                </p>
              </li>
              <li>
                <p>
                  <strong>Rate Code: </strong> {rateCode ? rateCode : 'Missing Rate Code'}
                </p>
              </li>
              <ul className="furtherDetailsList">
                <li>
                  <p>
                    <strong>Property ID: </strong> {gdsPropertyID ? gdsPropertyID : 'Missing Property ID'}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Hotel Name: </strong> {hotelName ? hotelName : 'Missing Property Name'}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Hotel Address: </strong> {streetAddress ? streetAddress : 'Missing Address'}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>City: </strong> {city ? city : 'Missing City'}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Date(s) Audited: </strong> {auditDate ? auditDate : 'Missing Audit Date'}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Rate(s) Audited: </strong>
                    {ratesAudited ? ratesAudited : 'Missing Rate(s) Audited'}
                  </p>
                </li>
              </ul>
            </ul>
            <p className="thankYou">Thank you,</p>
          </div>
        </div>
      
        <div className="emailButtonContainer">
          <button
            className="setProgramName"
            onClick={() => {
              const newProgramName = prompt('Enter Program Name Here:');
              setProgramName(newProgramName)
            }}
          >
            {programName ? 'Program Name Set!' : 'Enter Program Name'}
          </button>

          <button
            className="copyEmail"
            disabled= {programName ? false : true}
            onClick={() => {
              setCopied(true);
              copyEmail();
            }}
          >
            {copied ? 'Copied!' : 'Copy Email'}
          </button>
          <button
            className="sendEmail"
            disabled = {programName ? false : true}
            onClick={() => {
              const emailPlaceholder = prompt('Hotel Email');
              // setDestinationEmail(emailPlaceholder);
              awsEmailFunction(emailPlaceholder);
            }} // () => {
            //   setSending(true);
            //   const hotelEmail = prompt('Hotel Email');
            //   sendEmail(hotelEmail);
            // }}
          >
            {sent ? 'Sent!' : sending ? 'Sending' : 'Send Email'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailModal;
