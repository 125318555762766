import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

// test

import ChildGrid from './childGrid';
import Footer from '../../shared/layout/footer/footer';

// Mui Data Grid Imports
import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  GridRowId,
  GridCallbackDetails,
  LicenseInfo,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import type { } from '@mui/x-data-grid/themeAugmentation';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import WifiIcon from '@mui/icons-material/Wifi';
import ParkingIcon from '@mui/icons-material/LocalParking';
import BreakfastIcon from '@mui/icons-material/Flatware';
import ShuttleIcon from '@mui/icons-material/DirectionsBus';
import CityIcon from '@mui/icons-material/EmojiTransportation';
import { Tooltip } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import { Pagination, TablePagination } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';

// Font Awesome Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faSortUp, faRedoAlt, faStickyNote } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';

// import style sheets
import './manageRateLoadErrors.scss';
import './properties/propertyRow.scss';

// Local Imports
import EmailModal from './emailModal/emailModal';
import NotesModal from './notesModal/notesModal';
import PropertiesInfo from './properties/properties';

LicenseInfo.setLicenseKey('bc2929a685fe885b63da1cda8b2bee36T1JERVI6MzY1ODEsRVhQSVJZPTE2NzQ3MDU3NTIwMDAsS0VZVkVSU0lPTj0x');

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: ['Gordita'].join(','),
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },

  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    paddingLeft: 18,
    // margin: 0,
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 400,
    fontSize: 16,
  },
}));

const ManageRateLoadErrors = () => {
  const [propertiesData, setPropertiesData] = useState(null);
  const [emailStatus, setEmailStatus] = useState(false);
  const [notesStatus, setNotesStatus] = useState(false);
  const [individualPropertyData, setIndividualPropertyData] = useState(null);
  const [currentDetailsID, setCurrentDetailsID] = useState(null);
  const [hotels, setHotels] = useState(null);
  const [numberOfHotels, setNumberOfHotels] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [gdsID, setGdsID] = useState('0');
  const [loading, setLoading] = useState(true);
  const [programName, setProgramName] = useState(null);

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const handleChangeRowsPerPage = event => {
      setPageSize(parseInt(event.target.value, 10));
    };

    return (
      <div className="rateErrorPagination">
        <TablePagination
          component="div"
          count={numberOfHotels}
          page={page}
          onPageChange={(event, value) => apiRef.current.setPage(value - 1)}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Pagination
          color="primary"
          variant="text"
          shape="rounded"
          count={pageCount}
          page={page + 1}
          sx={{ marginLeft: 'auto', marginRight: '1rem' }}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
      </div>
    );
  }

  useEffect(() => {
    loadProperties();
  }, []);

  const toggleEmailModal = data => {
    if (data) {
      setEmailStatus(!emailStatus);
      setIndividualPropertyData(data);
    } else {
      setEmailStatus(!emailStatus);
    }
  };

  const toggleNotesModal = id => {
    setNotesStatus(!notesStatus);
    setCurrentDetailsID(id);
  };

  const loadProperties = async () => {
    const properties = await (await axios.get('/api/user/errors')).data;
    setPropertiesData(properties);
    setProgramName(properties._embedded?.auditErrorRecords2List[0].programName);
    const hotelData = await (await axios('api/user/hotelsNeedingAction')).data;
    if (hotelData._embedded && hotelData._embedded.tupleBackedMapList) {
      const individualHotelData = hotelData._embedded.tupleBackedMapList.map(hotel => {
        return hotel;
      });
      setHotels(individualHotelData);
      setNumberOfHotels(individualHotelData.length);
    }
    setLoading(false);
 
  };

  const rerunProperty = data => {
    if (data) {
      const url = 'api/user/errors/' + data + '/run';
      axios({
        method: 'post',
        url,
        timeout: 2000000,
      }).then(response => {
        if (response.statusText === 'OK') {
          loadProperties();
        }
      });
    }
  };

  const actionStatusChange = (id, event) => {
    let newStatus = null;

    if (event.target.value.toString() === '1st Supplier Contacted') {
      newStatus = 'SUPPLIERCONTACT1';
    }
    if (event.target.value.toString() === '2nd Supplier Contacted') {
      newStatus = 'SUPPLIERCONTACT2';
    }
    if (event.target.value.toString() === 'Ignore') {
      newStatus = 'IGNORE';
    }
    if (event.target.value.toString() === 'Take Action') {
      newStatus = 'TAKE_ACTION';
    }

    if (newStatus) {
      axios.post(`api/user/${id}/actionstatus`, {
        actionStatus: newStatus,
      });
      loadProperties();
    }
  };

  const formatDate = date => {
    const dates = date.split('-');
    return `${dates[1]}/${dates[2]}/${dates[0]}`;
  };

  let rateLoadedStatus = null;

  const amenityColor = status => {
    // Uncomment for colors
    // if (rateLoadedStatus === "Rate Not Loaded" || rateLoadedStatus === "Property Not Found") {
    return '#6A707E';
    // } else if (status === true) {
    //   return '#ED3743';
    // } else if (status === false) {
    //   return '#002067';
    // } else {
    //   return '#6A707E';
    // }
  };

  const cancelPolicyColor = () => {
    if (rateLoadedStatus === 'Rate Not Loaded' || rateLoadedStatus === 'Property Not Found') {
      return '#6A707E';
    } else {
      return '#55C88E';
    }
  };

  const getDetailPanelContent = React.useCallback(({ row }) => {
    if (row) {
      if (row.id && row.currentStatus) {
        return <ChildGrid id={row.id} parentStatus={row.currentStatus.actionStatus ? row.currentStatus.actionStatus.name : null} />;
      }
    }
  }, []);

  const getDetailPanelHeight = () => {
    return 400;
  };

  const getRateLoadedValue = rateFailLoad => {
    rateLoadedStatus = rateFailLoad;
    if (rateFailLoad === 'Rate Mismatch') {
      return 'Rate Mismatch';
    } else if (rateFailLoad === 'Rate Not Loaded') {
      return 'Rate Not Loaded';
    } else if (rateFailLoad === 'Rate Loaded Lower') {
      return 'Rate Loaded Lower';
    } else if (rateFailLoad === 'Rate Loaded Higher') {
      return 'Rate Loaded Higher';
    } else if (rateFailLoad === 'Property Not Found') {
      return 'Property Not Found';
    } else {
      return 'Rate Loaded Successfully';
    }
  };

  const rows: GridRowsProp = hotels
    ? hotels.map((hotel, index) => {
      return {
        id: hotel ? hotel.gdspropertyID : 1,
        auditRunDate: hotel ? (hotel.auditDate ? formatDate(hotel.auditDate) : 1) : 1,
        hotelInformation: hotel ? hotel.propertyHotelName : 'Missing Hotel Information',
        chainCode: hotel.chainCode ? hotel.chainCode : 'no hotel',
        rateAvailability: hotel ? hotel.rateAvailabilityPercent : 0,
        // rateLoaded: hotel ? (hotel.rateFailLoad ? getRateLoadedValue(hotel.rateFailLoad.description) : 'Rate Loaded Successfully') : 1,
        // cancelPolicy: hotel ? (hotel.cancellationFail ? 'Fail' : 'Pass') : 1,
        // amenities: hotel ? hotel : 1,
        currentStatus: hotel ? hotel.actionStatus.description : 'Take Action',
        actions: hotel ? hotel : 'actions',
        propertyData: hotel ? hotel : null,
      };
    })
    : [];
  const columns: GridColDef[] = [
    // Audit Run Date
    {
      field: 'auditRunDate',
      headerName: 'AUDIT RUN DATE',
      // flex: 1.25,
      width: 180,
      headerClassName: 'propertyColumnHeading',
      headerAlign: 'left',
      align: 'left',
      cellClassName: 'newPropertyRowContent',
    },

    // Hotel Information
    {
      field: 'hotelInformation',
      headerName: 'HOTEL INFORMATION',
      // flex: 2,
      width: 300,
      headerClassName: 'propertyColumnHeading',
      headerAlign: 'left',
      cellClassName: 'newPropertyRowContent',
      renderCell: params =>
        params.formattedValue ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '16px', color: '#6A707E', fontWeight: '400' }}>
              {params.formattedValue ? params.formattedValue : 'Missing Property Name'}
            </p>
            <p style={{ fontSize: '16px', color: '#6A707E' }}>
              {params.row.actions.city ? params.row.actions.city : 'Missing City'}, {params.row.actions.stateProvince}
            </p>
          </div>
        ) : (
          <></>
        ),
    },

    // Chain Code
    {
      field: 'chainCode',
      headerName: 'CHAIN CODE',
      // flex: 1,
      width: 150,
      headerAlign: 'center',
      headerClassName: 'propertyColumnHeading',
      align: 'center',
      cellClassName: 'newPropertyRowContent',
    },

    // Rate Availability
    {
      field: 'rateAvailability',
      headerName: 'RATE AVAILABILITY',
      // flex: 1.5,
      width: 200,
      headerAlign: 'center',
      headerClassName: 'propertyColumnHeading',
      align: 'center',
      cellClassName: 'newPropertyRowContent',
      renderCell: params => <div>{params.formattedValue}%</div>,
    },

    // // Rate Loaded
    // {
    //   field: 'rateLoaded',
    //   headerName: 'RATE LOADED',
    //   // flex: 1,
    //   width: 175,
    //   headerAlign: 'center',
    //   headerClassName: 'propertyColumnHeading',
    //   align: 'center',
    //   renderCell: params =>
    //     params.formattedValue ? (
    //       <p>{params.formattedValue}</p>
    //     ) : (
    //       // params.formattedValue === 'Rate Loaded Higher' ? (
    //       //   <Tooltip title="Rate Loaded Higher" placement="top">
    //       //     <div
    //       //       style={{
    //       //         height: 12,
    //       //         width: 12,
    //       //         borderRadius: 50,
    //       //         backgroundColor: '#ED3743',
    //       //       }}
    //       //     ></div>
    //       //   </Tooltip>
    //       // ) : params.formattedValue === 'Rate Loaded Lower' ? (
    //       //   <Tooltip title="Rate Loaded Lower" placement="top">
    //       //     <div
    //       //       style={{
    //       //         height: 12,
    //       //         width: 12,
    //       //         borderRadius: 50,
    //       //         backgroundColor: '#E3AC07',
    //       //       }}
    //       //     ></div>
    //       //   </Tooltip>
    //       // ) : params.formattedValue === 'Property Not Found' ? (
    //       //   <Tooltip title="Property Not Found" placement="top">
    //       //     <div
    //       //       style={{
    //       //         height: 12,
    //       //         width: 12,
    //       //         borderRadius: 50,
    //       //         backgroundColor: '#ED3743',
    //       //       }}
    //       //     ></div>
    //       //   </Tooltip>
    //       // ) : params.formattedValue === 'Rate Not Loaded' ? (
    //       //   <Tooltip title="Rate Not Loaded" placement="top">
    //       //     <div
    //       //       style={{
    //       //         height: 12,
    //       //         width: 12,
    //       //         borderRadius: 50,
    //       //         backgroundColor: '#ED3743',
    //       //       }}
    //       //     ></div>
    //       //   </Tooltip>
    //       // ) : params.formattedValue === 'Rate Mismatch' ? (
    //       //   <Tooltip title="Rate Mismatch" placement="top">
    //       //     <div
    //       //       style={{
    //       //         height: 12,
    //       //         width: 12,
    //       //         borderRadius: 50,
    //       //         backgroundColor: '#ED3743',
    //       //       }}
    //       //     ></div>
    //       //   </Tooltip>
    //       // ) : (
    //       //   <Tooltip title={'Rate Loaded Sucessfully'} placement="top">
    //       //     <CheckIcon sx={{ color: '#55C88E' }}></CheckIcon>
    //       //   </Tooltip>
    //       // )
    //       <></>
    //     ),
    // },

    // Cancel Policy
    // {
    //   field: 'cancelPolicy',
    //   headerName: 'CANCEL POLICY',
    //   // flex: 1,
    //   width: 175,
    //   headerAlign: 'center',
    //   headerClassName: 'propertyColumnHeading',
    //   align: 'center',
    //   sortable: true,
    //   renderCell: params =>
    //     params.formattedValue === 'Fail' ? (
    //       <Tooltip title={'Fail'} placement="top">
    //         <div
    //           className="rateLoadedCircle"
    //           style={{
    //             height: 12,
    //             width: 12,
    //             borderRadius: 50,
    //             backgroundColor: '#ED3743',
    //           }}
    //         ></div>
    //       </Tooltip>
    //     ) : (
    //       <Tooltip title={'Pass'} placement="top">
    //         <CheckIcon sx={{ color: '#55C88E' }}></CheckIcon>
    //       </Tooltip>
    //     ),
    // },

    // // Amenities
    // {
    //   field: 'amenities',
    //   headerName: 'AMENITIES',
    //   // flex: 1,
    //   width: 150,
    //   headerClassName: 'propertyColumnHeading',
    //   headerAlign: 'center',
    //   align: 'center',
    //   sortable: false,
    //   renderCell: params =>
    //     params.formattedValue ? (
    //       <div>
    //         <Tooltip title={params.formattedValue.amenityFailWifi ? 'WiFi: Fail' : 'WiFi: Pass'} placement="top">
    //           <WifiIcon sx={{ color: amenityColor(params.formattedValue.amenityFailWifi) }}></WifiIcon>
    //         </Tooltip>
    //         <Tooltip title={params.formattedValue.amenityFailParking ? 'Parking: Fail' : 'Parking: Pass'} placement="top">
    //           <ParkingIcon sx={{ color: amenityColor(params.formattedValue.amenityFailParking) }}></ParkingIcon>
    //         </Tooltip>
    //         <Tooltip title={params.formattedValue.amenityFailBreakfast ? 'Breakfast: Fail' : 'Breakfast: Pass'} placement="top">
    //           <BreakfastIcon sx={{ color: amenityColor(params.formattedValue.amenityFailBreakfast) }}></BreakfastIcon>
    //         </Tooltip>
    //         <Tooltip title={params.formattedValue.amenityFailAirport ? 'Airport Shuttle: Fail' : 'Airport Shuttle: Pass'} placement="top">
    //           <ShuttleIcon sx={{ color: amenityColor(params.formattedValue.amenityFailAirport) }}></ShuttleIcon>
    //         </Tooltip>
    //         <Tooltip
    //           title={params.formattedValue.amenityFailTransportation ? 'Transportation: Fail' : 'Transportation: Pass'}
    //           placement="top"
    //         >
    //           <CityIcon sx={{ color: amenityColor(params.formattedValue.amenityFailTransportation) }}></CityIcon>
    //         </Tooltip>
    //       </div>
    //     ) : (
    //       <></>
    //     ),
    // },

    // Current Status
    {
      field: 'currentStatus',
      headerName: 'CURRENT STATUS',
      // flex: 1.25,
      width: 200,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'propertyColumnHeading',
      sortable: true,
      filterable: true,
      renderCell: params => (
        <select
          name=""
          id=""
          defaultValue={params.formattedValue}
          onChange={actionStatusChange.bind(this, params.row.propertyData.hotelAuditPropertyDetailsID)}
          className={'propertyRowContent newPropertyCurrentStatus'}
        >
          <option value="Take Action">Take Action</option>
          <option value="1st Supplier Contacted">1st Supplier Contact</option>
          <option value="2nd Supplier Contacted">2nd Supplier Contact</option>
          <option value="Ignore">Ignore</option>
        </select>
      ),
    },

    // Actions
    {
      field: 'actions',
      headerName: 'ACTIONS',
      // flex: 1,
      width: 100,
      headerClassName: 'propertyColumnHeading',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: params => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Tooltip title="Contact Supplier" placement="top">
            <div className="commentCircle">
              <FontAwesomeIcon
                className="commentIcon"
                icon={faCommentAlt}
                flip="horizontal"
                onClick={() => {
                  toggleEmailModal(params);
                }}
              />
            </div>
          </Tooltip>

          <Tooltip title="Take Note" placement="top">
            <div>
              <FontAwesomeIcon
                className="notesIcon"
                icon={faStickyNote}
                onClick={() => {
                  toggleNotesModal(params.value.hotelAuditPropertyDetailsID);
                }}
              />
            </div>
          </Tooltip>

          <Tooltip title="Recheck Property: Coming Soon" placement="top">
            <div>
              <FontAwesomeIcon className="reRunIcon" icon={faRedoAlt} onClick={() => rerunProperty(params.formattedValue.gdspropertyID)} />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const apiRef = useGridApiRef();

  return (
    <div>
      <div className="propertiesNeedingActionContainer">
        <EmailModal active={emailStatus} programName={programName} toggleModal={toggleEmailModal} data={individualPropertyData} />
        {notesStatus ? (
          <NotesModal active={notesStatus} toggleModal={() => setNotesStatus(!notesStatus)} detailsID={currentDetailsID} />
        ) : (
          <div></div>
        )}
        <div className="propertiesNeedingActionTitleContainer">
          <h1 className="propertiesNeedingActionTitle">Manage Rate Load Errors</h1>
          <Link className="logoutLink" to="/audits">
            <button className="auditsDashboardLink">
              <p>Audits Dashboard</p>
            </button>
          </Link>
        </div>
        <div className="container" style={{ height: 'auto', border: 'none', borderRadius: '30px' }}>
          <DataGridPro
            initialState={{
              sorting: {
                sortModel: [
                  { field: 'auditRunDate', sort: 'desc' },
                  { field: 'hotelInformation', sort: 'asc' },
                ],
              },
            }}
            style={{ border: 'none', backgroundColor: '#fff', borderRadius: '30px', marginBottom: '2rem', overflow: 'hidden' }}
            rows={rows}
            columns={columns}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={params => getDetailPanelContent(params)}
            pageSize={pageSize}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            pagination
            rowsPerPageOptions={[5, 10, 20]}
            autoHeight
            components={{
              Pagination: CustomPagination,
            }}
            loading={loading}
            apiRef={apiRef}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageRateLoadErrors;