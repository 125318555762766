import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Tooltip } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import WifiIcon from '@mui/icons-material/Wifi';
import ParkingIcon from '@mui/icons-material/LocalParking';
import BreakfastIcon from '@mui/icons-material/Flatware';
import ShuttleIcon from '@mui/icons-material/DirectionsBus';
import CityIcon from '@mui/icons-material/EmojiTransportation';

import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  LicenseInfo,
} from '@mui/x-data-grid-pro';

import './childGrid.scss';

LicenseInfo.setLicenseKey('bc2929a685fe885b63da1cda8b2bee36T1JERVI6MzY1ODEsRVhQSVJZPTE2NzQ3MDU3NTIwMDAsS0VZVkVSU0lPTj0x');

interface ChildGrid {
  id;
  parentStatus;
}

const ChildGrid = props => {
  const [errorData, setErrorData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [props.parentStatus]);

  const getData = async () => {
    const data = await axios.get('api/user/hotelsNeedingAction/' + props.id);
    if (data) {
      if (data.data) {
        setLoading(false);
        setErrorData(data.data.reverse());
      }
    }
  };

  const formatDate = date => {
    const dates = date.split('-');
    return `${dates[1]}/${dates[2]}/${dates[0]}`;
  };

  let rateLoadedStatus = null;

  const amenityColor = status => {
    // Uncomment for colors
    // if (rateLoadedStatus === "Rate Not Loaded" || rateLoadedStatus === "Property Not Found") {
    return '#6A707E';
    // } else if (status === true) {
    //   return '#ED3743';
    // } else if (status === false) {
    //   return '#002067';
    // } else {
    //   return '#6A707E';
    // }
  };

  const cancelPolicyColor = () => {
    if (rateLoadedStatus === 'Rate Not Loaded' || rateLoadedStatus === 'Property Not Found') {
      return '#6A707E';
    } else {
      return '#55C88E';
    }
  };

  const getRateLoadedValue = rateFailLoad => {
    rateLoadedStatus = rateFailLoad;
    if (rateFailLoad === 'Rate Mismatch') {
      return 'Rate Mismatch';
    } else if (rateFailLoad === 'Rate Not Loaded') {
      return 'Rate Not Loaded';
    } else if (rateFailLoad === 'Rate Loaded Lower') {
      return 'Rate Loaded Lower';
    } else if (rateFailLoad === 'Rate Loaded Higher') {
      return 'Rate Loaded Higher';
    } else if (rateFailLoad === 'Property Not Found') {
      return 'Property Not Found';
    } else {
      return 'Rate Loaded Successfully';
    }
  };

  const formatReturnedArray = providedData => {
    if (Array.isArray(providedData)) {
      const newArray = providedData.filter(month => {
        if (month !== null) {
          return month;
        }
      });
      const newArrayCleanedString = newArray.toString().replace(/,/g, ', ');
      return newArrayCleanedString;
    } else {
      return providedData;
    }
  };

  const rows: GridRowsProp = errorData
    ? errorData.map((data, index) => {
      return {
        id: data ? index : 1,
        dateShopped: "need more data",
        auditName: data ? (data.auditName ? data.auditName : 'missing') : 'missing',
        contractedRate: data ? (data.rate1StaticRoomRateAmount ? formatReturnedArray(data.rate1StaticRoomRateAmount) : 0) : 0,
        rateFound: data
          ? data.ratesFound
            ? data.ratesFound.length === 0
              ? 'no rates found'
              : formatReturnedArray(data.ratesFound)
            : data.ratesFound
          : 'no rates found',
        cancellationExpected: data ? (data.rate1CancellationPolicy ? data.rate1CancellationPolicy : 0) : 0,
        cancellationFound: data ? (data.cancellationFail ? 'Fail' : 'Pass') : 1,
        amenities: data ? data : 1,
      };
    })
    : [];

  const columns: GridColDef[] = [
    // Date Shopped
    {
      field: 'dateShopped',
      headerName: 'Date Shopped',
      width: 160,
      headerClassName: 'propertyColumnHeading',
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'newPropertyRowContent',
    },

    // Audit Name
    {
      field: 'auditName',
      headerName: 'Audit Name',
      width: 200,
      headerClassName: 'propertyColumnHeading',
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'newPropertyRowContent',
    },

    // Rate Expected
    {
      field: 'contractedRate',
      headerName: 'Contracted Rate',
      width: 180,
      headerAlign: 'center',
      headerClassName: 'propertyColumnHeading',
      align: 'center',
      cellClassName: 'newPropertyRowContent',
    },

    // Rate Found
    {
      field: 'rateFound',
      headerName: 'Rate Found',
      width: 160,
      headerAlign: 'center',
      headerClassName: 'propertyColumnHeading',
      align: 'center',
      sortable: true,
      renderCell: params => (params.formattedValue ? <p className="newPropertyRowContent">{params.formattedValue}</p> : <></>),
    },

    // Cancellation Expected
    {
      field: 'cancellationExpected',
      headerName: 'Cancellation Expected',
      width: 180,
      headerAlign: 'center',
      headerClassName: 'propertyColumnHeading',
      align: 'center',
      cellClassName: 'newPropertyRowContent',
    },

    // Cancellation Found
    {
      field: 'cancellationFound',
      headerName: 'Cancellation Found',
      width: 180,
      headerAlign: 'center',
      headerClassName: 'propertyColumnHeading',
      align: 'center',
      sortable: true,
      renderCell: params =>
        params.formattedValue === 'Fail' ? (
          <Tooltip title={'Fail'} placement="top">
            <div
              className="rateLoadedCircle"
              style={{
                height: 12,
                width: 12,
                borderRadius: 50,
                backgroundColor: '#ED3743',
              }}
            ></div>
          </Tooltip>
        ) : (
          <Tooltip title={'Pass'} placement="top">
            <CheckIcon sx={{ color: cancelPolicyColor() }}></CheckIcon>
          </Tooltip>
        ),
    },

    // Amenity Icons
    {
      field: 'amenities',
      headerName: 'Amenities: Coming Soon',
      width: 200,
      headerClassName: 'propertyColumnHeading',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: params => (
        <div>
          <Tooltip title={params.formattedValue.amenityFailWifi ? 'WiFi: Fail' : 'WiFi: Pass'} placement="top">
            <WifiIcon sx={{ color: amenityColor(params.formattedValue.amenityFailWifi) }}></WifiIcon>
          </Tooltip>
          <Tooltip
            title={params.formattedValue.amenityFailParking ? 'Parking: Fail' : 'Parking: Pass'}
            placement="top"
          >
            <ParkingIcon sx={{ color: amenityColor(params.formattedValue.amenityFailParking) }}></ParkingIcon>
          </Tooltip>
          <Tooltip
            title={params.formattedValue.amenityFailBreakfast ? 'Breakfast: Fail' : 'Breakfast: Pass'}
            placement="top"
          >
            <BreakfastIcon sx={{ color: amenityColor(params.formattedValue.amenityFailBreakfast) }}></BreakfastIcon>
          </Tooltip>
          <Tooltip
            title={params.formattedValue.amenityFailAirport ? 'Airport Shuttle: Fail' : 'Airport Shuttle: Pass'}
            placement="top"
          >
            <ShuttleIcon sx={{ color: amenityColor(params.formattedValue.amenityFailAirport) }}></ShuttleIcon>
          </Tooltip>
          <Tooltip
            title={
              params.formattedValue.amenityFailTransportation ? 'Transportation: Fail' : 'Transportation: Pass'
            }
            placement="top"
          >
            <CityIcon sx={{ color: amenityColor(params.formattedValue.amenityFailTransportation) }}></CityIcon>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div style={{ width: '100%', height: 350 }}>
      <DataGridPro
        sx={{ width: '96%', marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem', marginBottom: '1rem', borderRadius: '20px' }}
        rows={rows}
        columns={columns}
        loading={loading}
      />
    </div>
  );
};

export default ChildGrid;
