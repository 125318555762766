import PreviousMap from 'postcss/lib/previous-map';
import React from 'react';

// import additional components
import AuditRow from './auditRow';

interface RecentRecurringAudits {
  rRAuditData;
  refreshAudits;
  toggleModal;
  toggleViewAndDownloadModal;
  toggleRerunAuditModal;
  passAuditID;
  passDeleteAuditID;
  passAuditName;
  passScheduleType;
  setDownloadStatus;
  updateID;
  passLastRunDate;
  toggleDeleteAuditModalStatus;
}

const RecentRecurringAudits = props => {
  let biggestID = 0;

  if (props.rRAuditData) {
    if (props.rRAuditData.page.number + 1 > 0 && props.rRAuditData._embedded) {
      if (props.rRAuditData.page.number + 1 === 1) {
        for (let i = 0; i < props.rRAuditData._embedded.recentReoccuringAuditsList.length; i++) {
          if (biggestID <= props.rRAuditData._embedded.recentReoccuringAuditsList[i].auditID) {
            biggestID = props.rRAuditData._embedded.recentReoccuringAuditsList[i].auditID;
          }
        }
      }

      return props.rRAuditData._embedded.recentReoccuringAuditsList
        .slice(0)
        .map(data => (
          <AuditRow
            key={data.auditID}
            data={data}
            toggleModal={props.toggleModal}
            toggleViewAndDownloadModal={props.toggleViewAndDownloadModal}
            refreshAudits={props.refreshAudits}
            active={data.auditID === biggestID ? true : false}
            passAuditID={props.passAuditID}
            passDeleteAuditID={props.passDeleteAuditID}
            passAuditName={props.passAuditName}
            passScheduleType={props.passScheduleType}
            setDownloadStatus={props.setDownloadStatus}
            updateID={props.updateID}
            passLastRunDate={props.passLastRunDate}
            toggleDeleteAuditModalStatus={props.toggleDeleteAuditModalStatus}
            toggleRerunAuditModal={props.toggleRerunAuditModal}
          />
        ));
    }
  }

  return null;
};

export default RecentRecurringAudits;
