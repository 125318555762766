import React from 'react';

import './deleteModal.scss';

interface DeleteModal {
  cancel;
  status;
  deleteNote;
}

const DeleteModal = props => {
  return (
    <div className={props.status ? 'deleteModalActive' : 'deleteModalInactive'}>
      <div className="deleteConfirmationBox">
        <div className="deleteNoteTitleBox">
          <h1 className="deleteNoteTitle">Delete This Note?</h1>
        </div>
        <div className="cancelDeleteContainer">
          <button className="cancelButton" onClick={() => props.cancel()}>
            cancel
          </button>
          <button className="deleteButton" onClick={() => props.deleteNote()}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
