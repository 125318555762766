import React from "react";
// Material UI Components
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Tooltip } from '@material-ui/core';

import './auditResultSummary.scss';

interface AuditResultsSummary {
    currentAuditRunStatus,
    summaryData
  }

const AuditResultsSummary = (props) => {

    return(
        <div className="resultBoxes">
            <div className="resultBox border">
                <div className="labelSection">
                  <h5> Savings at Risk</h5>
                  <Tooltip
                    placement="top"
                    title="
                     Savings are calculated by
                     estimating the potential
                     cost avoidance of
                     additional spend due to
                     incorrect rate, cancellation policy, and
                     amenities for hotels that
                     fail an audit, compared
                     against historical
                     production.
                     "
                  >
                    <div className="moreInfoCircle">
                      <QuestionMarkIcon style={{ color: '#6A707E', height: '12px' }} />
                    </div>
                  </Tooltip>
                </div>
                <h4 className="auditTotals savings">
                  {props.currentAuditRunStatus ? '$36,245.53' : 'Pending'}
                </h4>
            </div>
            <div className="resultBox border">
              <div className="labelSection">
                <h5 className="label"> Hotels Audited </h5>
                <Tooltip
                    placement="top"
                    title="Total amount of Hotels Audited"
                  >
                    <div className="moreInfoCircle">
                      <QuestionMarkIcon style={{ color: '#6A707E', height: '12px' }} />
                    </div>
                  </Tooltip>
              </div>
               <h4 className="auditTotals">
                {props.currentAuditRunStatus ? props.summaryData.totalProcessed : 'Pending'}
              </h4>
            </div>

            <div className="resultBox border">
                <div className="labelSection">
                  <h5> Rates Loaded </h5>
                  <Tooltip
                    placement="top"
                    title="Amount of Audits that where analyzed successfully"
                  >
                    <div className="moreInfoCircle">
                      <QuestionMarkIcon style={{ color: '#6A707E', height: '12px' }} />
                    </div>
                  </Tooltip>
                </div>
                <h4 className="auditTotals">
                  {props.currentAuditRunStatus ? 10 : 'Pending'}
                </h4>
            </div>

            <div className="resultBox border ">
                <div className="labelSection">
                  <h5> Rate Availability </h5>
                  <Tooltip
                    placement="top"
                    title="TBD"
                  >
                    <div className="moreInfoCircle">
                      <QuestionMarkIcon style={{ color: '#6A707E', height: '12px' }} />
                    </div>
                  </Tooltip>
                </div>
                <h4 className="auditTotals">
                  {props.currentAuditRunStatus ? '10%' : 'Pending'}
                </h4>
            </div>

            <div className="resultBox border ">
              <div className="labelSection">
                <h5>Rate Accuracy</h5>
                <Tooltip
                    placement="top"
                    title="Rate of Audits that failed during the analysis process"
                  >
                  <div className="moreInfoCircle">
                    <QuestionMarkIcon style={{ color: '#6A707E', height: '12px' }} />
                  </div>
                </Tooltip>
              </div>
              <h4 className="auditTotals">
                {props.currentAuditRunStatus ? '43%' : 'Pending'}
              </h4>
            </div>

            <div className="resultBox ">
              <div className="labelSection">
                  <h5>Rate Loaded Higher</h5>
                  <Tooltip
                      placement="top"
                      title="TBD"
                    >
                      <div className="moreInfoCircle">
                        <QuestionMarkIcon style={{ color: '#6A707E', height: '12px' }} />
                      </div>
                    </Tooltip>
              </div>
              <h4 className="auditTotals">
                {props.currentAuditRunStatus ? props.summaryData.rateLoadedHigher : 'Pending'}
              </h4>
            </div>
          </div>
    );
}

export default AuditResultsSummary


