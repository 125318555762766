import React, { useState, useEffect } from 'react';

import Axios from 'axios';
import { AuthenticatedLink } from 'app/shared/util/AuthenticatedLink';

import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  LicenseInfo,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { styled } from '@mui/material/styles';

LicenseInfo.setLicenseKey('bc2929a685fe885b63da1cda8b2bee36T1JERVI6MzY1ODEsRVhQSVJZPTE2NzQ3MDU3NTIwMDAsS0VZVkVSU0lPTj0x');

import './viewAndDownloadModal.scss';

interface ViewAndDownloadModal {
  active;
  toggleModal;
  id;
  individualAuditDownloadStatus;
  recentRecurringAuditDownloadStatus;
  auditResultSummaryDownloadStatus;
  hotelPropertiesDownloadStatus;
  currentID;
  currentAuditName;
  scheduleType;
  lastRunDate;
  uploadingStatus;
}

const ViewAndDownloadModal = props => {
  const [cols, setCols] = useState(null);
  const [rows, setRows] = useState([]);

  const getHotelInfo = property => {
    try {
      return JSON.parse(property.hotelInfoJSON);
    } catch (e) {
      return null;
    }
  };

  const loadIndividualAuditData = async () => {
    if (props.id) {
      const getIndividualAuditData = await Axios.get('api/user/audit/' + props.id + '/details');
      let individualAuditData = null;
      if (getIndividualAuditData.data._embedded) {
        individualAuditData = getIndividualAuditData.data._embedded.hotelAuditDetailResultsList;
      }
      const rowData = individualAuditData
        ? individualAuditData.map(property => {
            if (property && property.hotelInfoJSON) {
              const data = getHotelInfo(property);
              return {
                id: property.hotelAuditDetailResultID ? property.hotelAuditDetailResultID : '0',
                propertyAuditFailure: property.propertyAuditFailure ? property.propertyAuditFailure.description : 'Success',
                auditDate: property.auditDate ? property.auditDate : 'null',
                hotelPropertyName: data.HotelName ? data.HotelName : 'null',
                actionStatus: property.actionStatus ? property.actionStatus.description : 'null',
                contactPhoneNumber: data.LocationInfo ? data.LocationInfo.Contact.Phone : 'null',
                sabreHotelCode: data.HotelCode ? data.HotelCode : 'null',
                latitude: data.LocationInfo.Latitude ? data.LocationInfo.Latitude : 'null',
                longitude: data.LocationInfo.Longitude ? data.LocationInfo.Longitude : 'null',
                chainCode: data.ChainCode ? data.ChainCode : 'null',
                brandCode: data.BrandCode ? data.BrandCode : 'null',
                propertyAddressStreet1: data.LocationInfo ? data.LocationInfo.Address.AddressLine1 : 'null',
                propertyAddressCity: data.LocationInfo.Address.CityName.value ? data.LocationInfo.Address.CityName.value : 'null',
                propertyZip: data.LocationInfo ? data.LocationInfo.Address.PostalCode : 'null',
                propertyCountry: data.LocationInfo ? data.LocationInfo.Address.CountryName.value : 'null',
                propertyAddressStreet2: data.Address ? data.LocationInfo.Address.AddressLine2 : 'null',
              };
            } else
              return {
                id: property.hotelAuditDetailResultID ? property.hotelAuditDetailResultID : '0',
                propertyAuditFailure: property.propertyAuditFailure ? property.propertyAuditFailure.description : 'Success',
                auditDate: property.auditDate ? property.auditDate : 'null',
                hotelPropertyName: 'null',
                actionStatus: property.actionStatus ? property.actionStatus.description : 'null',
                contactPhoneNumber: 'null',
                sabreHotelCode: 'null',
                latitude: 'null',
                longitude: 'null',
                chainCode: 'null',
                brandCode: 'null',
                propertyAddressStreet1: 'null',
                propertyAddressCity: 'null',
                propertyZip: 'null',
                propertyCountry: 'null',
                propertyAddressStreet2: 'null',
              };
          })
        : null;

      const columns: GridColDef[] = [
        { field: 'propertyAuditFailure', headerName: 'Property Audit Failure', width: 190 },
        { field: 'auditDate', headerName: 'Audit Date', width: 120 },
        { field: 'hotelPropertyName', headerName: 'Hotel Property Name', width: 300 },
        { field: 'actionStatus', headerName: 'Action Status', width: 190 },
        { field: 'contactPhoneNumber', headerName: 'Contact Phone Number', width: 200 },
        { field: 'sabreHotelCode', headerName: 'Sabre Hotel Code', width: 170 },
        { field: 'latitude', headerName: 'Latitude', width: 120 },
        { field: 'longitude', headerName: 'Logitude', width: 120 },
        { field: 'chainCode', headerName: 'Chain Code', width: 120 },
        { field: 'brandCode', headerName: 'Brand Code', width: 190 },
        { field: 'propertyAddressStreet1', headerName: 'Property Address Street 1', width: 220 },
        { field: 'propertyAddressCity', headerName: 'Property Address City', width: 190 },
        { field: 'propertyZip', headerName: 'Property Zip', width: 120 },
        { field: 'propertyCountry', headerName: 'Property Country', width: 190 },
        { field: 'propertyAddressStreet2', headerName: 'Property Address Street 2', width: 220 },
      ];

      setRows(rowData);
      setCols(columns);
    }
  };

  const loadRecentRecurringAuditData = async () => {
    const getRecentRecurringAuditData = await Axios.get('api/user/audit/recent');
    const recentRecurringAuditData = getRecentRecurringAuditData.data._embedded.recentReoccuringAuditsList;
    const recentRows = recentRecurringAuditData
      ? recentRecurringAuditData.map(audit => {
          return {
            id: audit.auditID ? audit.auditID : 'null',
            auditName: audit.auditName ? audit.auditName : 'null',
            auditScheduleTypeName: audit.auditScheduleTypeName ? audit.auditScheduleTypeName.description : 'null',
            nextRunDate: audit.nextRunDate ? audit.nextRunDate : 'null',
          };
        })
      : null;

    const columns: GridColDef[] = [
      { field: 'auditName', headerName: 'Audit Name', flex: 1 },
      { field: 'auditScheduleTypeName', headerName: 'Audit Schedule Type Name', flex: 1 },
      { field: 'nextRunDate', headerName: 'Next Run Date', flex: 1 },
    ];

    setCols(columns);
    setRows(recentRows);
  };

  // const loadAuditResultsSummaryData = async () => {
  //   const getAuditResultsSummaryData = await Axios.get('api/user/audit/' + props.currentID + '/drilldown');
  //   const auditResultsSummaryData = getAuditResultsSummaryData.data._embedded.auditSummaryDTOList;
  //
  //   setRows(
  //     auditResultsSummaryData.map(result => {
  //       return {
  //         groupByValue: result.groupByValue ? result.groupByValue : 'null',
  //         totalRecords: result.totalRecords ? result.totalRecords : 'null',
  //         totalProcessed: result.totalProcessed ? result.totalProcessed : 'null',
  //         success: result.success ? result.success : 'null',
  //         hotelNotFound: result.hotelNotFound ? result.hotelNotFound : 'null',
  //         amenityMismatch: result.amenityMismatch ? result.amenityMismatch : 'null',
  //         notAuditedError: result.notAuditedError ? result.notAuditedError : 'null',
  //         rateLoadedHigher: result.rateLoadedHigher ? result.rateLoadedHigher : 'null',
  //         rateLoadedLower: result.rateLoadedLower ? result.rateLoadedLower : 'null',
  //         rateMismatch: result.rateMismatch ? result.rateMismatch : 'null',
  //         rateNotFound: result.rateNotFound ? result.rateNotFound : 'null',
  //         rateNotLoaded: result.rateNotLoaded ? result.rateNotLoaded : 'null',
  //         rateTooHigh: result.rateTooHigh ? result.rateTooHigh : 'null',
  //       };
  //     })
  //   );

  //   setCols([
  //     { name: 'groupByValue', header: 'Chain Code', minWidth: 187 },
  //     { name: 'totalRecords', header: 'Total Records', minWidth: 187 },
  //     { name: 'totalProcessed', header: 'Total Processed', minWidth: 187 },
  //     { name: 'success', header: 'Success', minWidth: 87 },
  //     { name: 'hotelNotFound', header: 'Hotel Not Found', minWidth: 87 },
  //     { name: 'amenityMismatch', header: 'Amenity Mismatch', minWidth: 87 },
  //     { name: 'notAuditedError', header: 'Not Audited Error', minWidth: 87 },
  //     { name: 'rateLoadedHigher', header: 'Rate Loaded Higher', minWidth: 87 },
  //     { name: 'rateLoadedLower', header: 'Rate Loaded Lower', minWidth: 87 },
  //     { name: 'rateMismatch', header: 'Rate Mismatch', minWidth: 87 },
  //     { name: 'rateNotFound', header: 'Rate Not Found', minWidth: 87 },
  //     { name: 'rateNotLoaded', header: 'Rate Not Loaded', minWidth: 87 },
  //     { name: 'rateTooHigh', header: 'Rate Too High', minWidth: 87 },
  //   ]);
  // };

  // const loadHotelPropertiesData = async () => {
  //   const getHotelPropertiesData = await Axios.get('api/user/errors');
  //   const hotelPropertiesData = getHotelPropertiesData.data._embedded.auditErrorRecords2List;
  //
  //   setRows(
  //     hotelPropertiesData.map(property => {
  //       return {
  //         hotelName: property.hotelName ? property.hotelName : 'null',
  //         cityState: property.city && property.state ? `${property.city},  ${property.state}` : property.city ? property.city : 'null',
  //         auditTypeName: property.auditTypeName
  //           ? property.auditTypeName.map(typeName => {
  //               return `${typeName.description} `;
  //             })
  //           : 'null',
  //         propertyAuditFailure: property.propertyAuditFailure
  //           ? property.propertyAuditFailure.map((failure, index) => {
  //               if (index + 1 === property.propertyAuditFailure.length) {
  //                 return failure.description;
  //               } else {
  //                 return `${failure.description}, `;
  //               }
  //             })
  //           : 'null',
  //         actionStatus: property.actionStatus ? property.actionStatus[0].description : 'null',
  //       };
  //     })
  //   );
  //
  //   setCols([
  //     { name: 'hotelName', header: 'Hotel Name', minWidth: 250 },
  //     { name: 'cityState', header: 'City, State', minWidth: 100 },
  //     { name: 'auditTypeName', header: 'Audit Type', minWidth: 100 },
  //     { name: 'propertyAuditFailure', header: 'Audit Failure Type', minWidth: 200 },
  //     { name: 'actionStatus', header: 'Current Status', minWidth: 50 },
  //   ]);
  // };

  const returnDownloadName = () => {
    if (props.individualAuditDownloadStatus) {
      if (props.currentAuditName) {
        return props.currentAuditName;
      } else {
        return 'IndividualAudit.xlsx';
      }
    }

    if (props.recentRecurringAuditDownloadStatus) {
      return 'RecentAudits.xlsx';
    }

    if (props.auditResultSummaryDownloadStatus) {
      // FIXME
      return 'api/user/audit/recent.xlsx';
    }

    if (props.hotelPropertiesDownloadStatus) {
      // FIXME
      return 'api/user/errors.xlsx';
    }
  };

  const returnDownloadLink = () => {
    if (props.individualAuditDownloadStatus && props.id) {
      return 'api/user/audit/' + props.id + '/details.xlsx';
    }

    if (props.recentRecurringAuditDownloadStatus) {
      return 'api/user/audit/recent.xlsx';
    }

    if (props.auditResultSummaryDownloadStatus) {
      // FIXME
      return 'api/user/audit/recent.xlsx';
    }

    if (props.hotelPropertiesDownloadStatus) {
      // FIXME
      return 'api/user/errors.xlsx';
    }
  };

  const returnModalTitle = () => {
    if (props.individualAuditDownloadStatus) {
      return 'Individual Audit Details';
    }

    if (props.recentRecurringAuditDownloadStatus) {
      return 'Recent Recurring Audits Report Preview';
    }

    if (props.auditResultSummaryDownloadStatus) {
      return 'Audit Results Summary Report Preview';
    }

    if (props.hotelPropertiesDownloadStatus) {
      return 'All Hotel Properties Needing Action Report Preview';
    }
  };

  const returnClassName = () => {
    if (props.individualAuditDownloadStatus) {
      return 'viewAndDownloadDownloadButtonInactive';
    }

    if (props.recentRecurringAuditDownloadStatus) {
      return 'viewAndDownloadDownloadButton';
    }

    if (props.auditResultSummaryDownloadStatus) {
      return 'Audit Results Summary Report Preview';
    }

    if (props.hotelPropertiesDownloadStatus) {
      return 'All Hotel Properties Needing Action Report Preview';
    }
  };

  // useEffect(() => {
  //   if (props.active && cols === null) {
  //     if (props.individualAuditDownloadStatus) {
  //       loadIndividualAuditData();
  //     } /* (props.recentRecurringAuditDownloadStatus) */ else {
  //       loadRecentRecurringAuditData();
  //     } // else if (props.auditResultSummaryDownloadStatus) {
  //     //   loadAuditResultsSummaryData();
  //     // } else if (props.hotelPropertiesDownloadStatus) {
  //     //   loadHotelPropertiesData();
  //   }
  // });

  useEffect(() => {
    if (props.individualAuditDownloadStatus) {
      loadIndividualAuditData();
    } else {
      loadRecentRecurringAuditData();
    }
  }, [props.uploadingStatus, props.active]);

  return (
    <div>
      {props.active ? (
        <div className="viewAndDownloadModal">
          <div className="viewAndDownloadArea">
            <div className="viewAndDownloadTitleContainer title">
              <h1 className="viewAndDownloadTitle">{returnModalTitle()}</h1>
              <span
                className="closeViewAndDownloadModal"
                onClick={() => {
                  setCols(null);
                  props.toggleModal();
                }}
              >
                &times;
              </span>
            </div>
            <div className="viewAndDownloadContentContainer">
              {props.scheduleType === 'Recurring' && !props.lastRunDate && props.individualAuditDownloadStatus === true ? (
                <div className="scheduledAuditContainer">
                  <h3>Kudos on scheduling an audit!</h3>

                  <h3>Results will display after the scheduled audit run is complete.</h3>
                </div>
              ) : props.uploadingStatus && !props.lastRunDate && props.individualAuditDownloadStatus === true ? (
                <div className="scheduledAuditContainer">
                  <h3>Congrats, an audit is running!</h3>

                  <h3>Results will display after the current audit run is complete.</h3>
                </div>
              ) : cols ? (
                <DataGridPro rows={rows} columns={cols} pageSize={5} rowsPerPageOptions={[5]} />
              ) : (
                <div></div>
              )}
            </div>
            {props.individualAuditDownloadStatus ? (
              <></>
            ) : (
              <div className="viewAndDownloadButtonContainer">
                <a className="viewAndDownloadShareButton">SHARE</a>
                <div>
                  <AuthenticatedLink className={returnClassName()} url={returnDownloadLink()} filename={returnDownloadName()}>
                    DOWNLOAD
                  </AuthenticatedLink>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ViewAndDownloadModal;
