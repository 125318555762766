import { createRef } from 'react'
import { Auth } from 'aws-amplify';
import React from 'react';

export function AuthenticatedLink ({className, url, filename, children }) {
  const link = createRef<HTMLAnchorElement>()

  const handleAction = () => {
    if (link.current.href) { return }

    let authHeader;

    Auth.currentSession()
      .then(auth => {
        const token = auth.getIdToken().getJwtToken();
        if (token) {
          authHeader = `Bearer ${token}`;
        }
      })

    fetch(url, {
      headers: { authHeader }
    }).then(response => response.blob())
      .then(function(blob) {
        link.current.href = window.URL.createObjectURL(blob)
        link.current.download = filename
        link.current.click()
      });
  }

  return (
    <>
      <a className={className} ref={link} onClick={handleAction}>{children}</a>
    </>
  )
}
